import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from '../apiConfig';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL ,
  prepareHeaders: (headers, { getState }) => {
    const userDetailsString = localStorage.getItem("vlinks");
    const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;

    if (userDetails?.token) {
      headers.set("authorization", `Bearer ${userDetails.token}`);
    } 

    return headers
  },
})

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  baseQuery,
  tagTypes: ['Category',"Job","User","Recruiter","Candidate","SubCatecory","Credit"],
  endpoints: (builder) => ({
    // ...endpoints
  }),
})

