import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { ADSENSESLOTS, IMAGE_ROUTES, RECAPCHASITEKEY } from "../../apiConfig";
import Toggle from "../GlobalComp/ToggleButton";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSendLoginEmailOTPMutation, useSendLoginMobileOTPMutation } from "../../redux/slice/notificationApiSlice";
import { toast } from "react-toastify";
import { useAuthenticateOTPMutation } from "../../redux/slice/userServiceApiSlice";
import { useParams } from "react-router-dom";

const LoginOTP = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [sendLoginEmailOTP,{data:emailOtpData}]  = useSendLoginEmailOTPMutation()
  const [sendLoginMobileOTP,{data:mobileOtpData}]  = useSendLoginMobileOTPMutation()
  const [authenticateOTP,{data:loginotpdata}]  = useAuthenticateOTPMutation()
  const [emailMobile, setEmailMobile] = useState(null);
  const [emailMobileEmpty, setEmailMobileEmpty] = useState(false);
  const [otpEmpty,setOtpEmpty]=useState(false)
  const [OTPSent,setOTPSent] =useState(false)
  const [OTP,setOTP] =useState("")
  const navigate =useNavigate()
  const {uid}=useParams()
  useEffect(()=>{
    if(Number(uid)===2){
      setToggle(true)
    }
  },[uid])
    useEffect(()=>{
       if(emailOtpData?.success|| mobileOtpData?.success){
        setOTPSent(true)
       }
    },[emailOtpData,mobileOtpData])

    useEffect(()=>{
       if(loginotpdata?.success){
        if(!toggle){
          navigate({
            pathname: '/JobSearch',
            search: `?${createSearchParams({ catId:1})}`,
          });
        }else{
          navigate("/RecruiterDash");
        }
       }
    },[loginotpdata,navigate,toggle])

  const handleToggleChange = () => {
    setToggle(!toggle);
  };
  const handleVerify = () => {
    setIsVerified(pre=>!pre);
  };

  const handleSubmit = async () => {
    if (!toggle) {
      const mobilePattern = /^[0-9]{10}$/; 
      if (!mobilePattern.test(emailMobile)) {
        setEmailMobileEmpty(true);
        toast.error("Please Enter valid Mobile No", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        return;
      }
      if(OTPSent){
        if(!OTP){
            setOtpEmpty(true);
            toast.error("Please Enter OTP", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
            return;
        }else{
          const res= await authenticateOTP({userName:emailMobile,userRoleId:3,otp:OTP})
          if(!res?.data?.success){
            toast.error("Invalid OTP", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
           }else{
            localStorage.setItem("vlinks", JSON.stringify(res?.data?.result));
            toast.success("Login Successful", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
           }
        }
      }else{
        setEmailMobileEmpty(false)
        const res= await sendLoginMobileOTP({userName:emailMobile,userRoleId:3});
        if(!res?.data?.success){
            toast.error("Invalid Mobile Number", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
         }
      }
    } 
    else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
      if (!emailPattern.test(emailMobile)) {
        setEmailMobileEmpty(true);
        toast.error("Please Enter valid Email Address", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        return;
      }

      if(OTPSent){
        if(!OTP){
            setOtpEmpty(true);
            toast.error("Please Enter OTP", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
            return;
        }else{
           const res= await authenticateOTP({userName:emailMobile,userRoleId:2,otp:OTP})
           if(!res?.data?.success){
            toast.error("Invalid OTP", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
           }else{
            localStorage.setItem("vlinks", JSON.stringify(res?.data?.result));
            toast.success("Login Successful", {
                autoClose: 1000,
                style: {
                  width: "300px",
                },
              });
           }
        }
      }else{
        const res = await sendLoginEmailOTP({userName:emailMobile,userRoleId:2});
        if(!res?.data?.success){
          toast.error("Invalid Email Address", {
              autoClose: 1000,
              style: {
                width: "300px",
              },
            });
       }
      } 
    }
  };
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Login</title>
        <link rel="canonical" href="https://vlinkjobs.com/Login" />
      </Helmet>

      <div className="flex md:flex-row sm:flex-col gap-4 justify-between items-center px-2 bodybg  font-custom-font ">
      <div className="flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        <div className="md:min-h-screen w-[420px] flex items-center  justify-center md:py-2 sm:py-4 md:px-4">
          <div className="flex items-center flex-col space-y-4 bg-blue-200 md:px-10 w-full rounded-2xl border border-blue-400 hover:border-blue-600">
            <Link to="/">
              <img
                src={`${IMAGE_ROUTES.altLogo}`}
                alt="Logo"
                className="max-h-28"
              />
            </Link>
            <div className="flex flex-col items-center gap-5">
              <h2 className="relative text-center text-2xl font-extrabold text-blue-900">
                Login to your account
              </h2>
              <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
            </div>

            <div className="relative w-11/12 space-y-6">
              <div className="rounded-md shadow-sm space-y-6">
                <div className="flex flex-col gap-2">
                <label htmlFor="email">Enter Email</label>
                <input
                  id="email"
                  value={emailMobile??""}
                  onChange={(e) => {setEmailMobile(e.target.value);setEmailMobileEmpty(false)}}
                  className={`relative block w-full px-3 py-2 border ${
                    emailMobileEmpty
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  placeholder={toggle ? "Enter Valid Email Address" : "Enter Valid Mobile Number"}
                />
                {emailMobileEmpty&&<p className="text-red-600 text-sm">{toggle?"*Enter Valid Email Address":"*Enter Valid Mobile Number"}</p>}
                </div>
                {OTPSent && (
                  <div className="flex flex-col gap-2">
                    <label htmlFor="otpInput">Enter OTP</label>
                    <input
                      id="otpInput"
                      value={OTP}
                      onChange={(e) => {setOTP(e.target.value);setOtpEmpty(false)}}
                      className={`relative block w-full px-3 py-2 border ${
                        otpEmpty
                          ? "border-red-500 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter OTP"
                    />
                     {otpEmpty&&<p className="text-red-600 text-sm">*Enter Valid OTP</p>}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>
                <div className="text-sm">
                  <Link
                    className="font-medium text-sm text-blue-900 hover:text-black hover:cursor-pointer"
                    to={`/Login/${toggle?2:3}`}
                  >
                    Sign in with password
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-center">
                 <ReCAPTCHA
                  sitekey={RECAPCHASITEKEY}
                  size="normal"
                  onChange={handleVerify}
                /> 
                <button onClick={handleSubmit} className="bg-yellow-500  w-full text-black font-bold py-2 px-4 mt-2 rounded-lg" disabled={!isVerified}>
                  {OTPSent ? "Sign In" : "Send OTP"}
                </button>
              </div>
              <div>
                <div className="flex items-center justify-end mb-2 gap-4">
                  <div className="">New Here?</div>
                 {/*  <Link
                    to={`/Register/${toggle?2:3}`}
                    className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Sign Up
                  </Link> */}
                  <button  onClick={()=>navigate(`/Register/${toggle?2:3}`)} >Sign Up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex   items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
      </div>
    </>
  );
};

export default LoginOTP;
