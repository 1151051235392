import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { IMAGE_ROUTES } from "../../apiConfig"
import { IoIosLogOut } from "react-icons/io";
import { BsBriefcaseFill } from "react-icons/bs";
import { IoBriefcaseSharp } from "react-icons/io5";
import { GiOfficeChair } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";

export const TopNavBar = () => {
  const navigate =useNavigate()
    const LogOut = () =>{
        localStorage.removeItem(`vlinks`);
        navigate("/")
        window.location.reload();
      }
  return (
    <div className="flex justify-between  md:px-6 shadow-lg top-0 z-10 w-full items-center fixed bg-white text-black">
        <NavLink to="/admindash/requiredapprovaljobs">
        <img src={`${IMAGE_ROUTES.mainLogo}`} className="h-20" alt="Logo" />
        </NavLink>
       <button className="flex items-center gap-2 bg-red-800 text-white p-2 rounded-md" onClick={LogOut}>Logout<IoIosLogOut /></button> 
    </div>
  )
}

const NavLinksRoute = [{id:1,name:"Jobs For Approval",route:"/admindash/requiredapprovaljobs",icon:<BsBriefcaseFill size="16" />},
{id:2,name:"Approved Jobs",route:"/admindash/approvedjobs",icon:<IoBriefcaseSharp size="16" />},
{id:3,name:"Total Recruiters",route:"/admindash/totalrecruiters",icon:<GiOfficeChair size="16" />}, 
{id:4,name:"View Profiles",route:"/admindash/adminviewprofiles",icon:<HiUserGroup size="16"/>}]

const SideNavBar = () => {
    const { pathname } = useLocation();
  return (
    <div className="flex md:flex-col flex-wrap font-custom-font gap-4 sm:pt-24 shadow-md md:w-[220px] w-full px-4 text-md font-medium bg-blue-500 text-white fixed  md:h-screen">
        {NavLinksRoute.map(rou=><div className="flex h-[48px] justify-between hover:text-red-400" key={rou.id}>
        <NavLink to={`${rou.route}`} className={`flex gap-2 items-center ${pathname===rou.route&& `text-red-500`}`}><span>{rou.icon}</span>{rou.name}</NavLink>
        </div>)}
    </div>
  )
}

export default SideNavBar