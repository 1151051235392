// const API_BASE_URL = 'https://vilacampus.in/vlinkjobsapi';
// const API_STATIC_URL = 'https://vilacampus.in/vlinkjobsapi/static/';

const API_BASE_URL = 'https://vlinkjobs.com/vlinkapi';
const API_STATIC_URL = 'https://vlinkjobs.com/vlinkapi/static/';

// const API_BASE_URL = 'http://localhost:3500/vlinkapi';
// const API_STATIC_URL = 'http://localhost:3500/vlinkapi/static/';

const PAYMENT_GATEWAY_KEY_ID = 'rzp_test_XzqZYz0SXAGhOs'
const PAYMENT_GATEWAY_KEY_SECRET = '65vWQpkaOTt3XS5OlW3B96NI'
const RECAPCHASITEKEY= "6LcWA5QpAAAAAGY39YYklGX-gSN_H-lshRLISBfy"
const RECAPCHASECRETKEY= "6LcWA5QpAAAAAB2a9zXb5D5hoNp8qmQTBINZAWrO"
const ADSENSECLIENTID = 'ca-pub-5656705273115236';
const ADSENSESLOTS = {
  bannerAd: '4743934832',
  inLineAd: 'ca-app-pub-2122666337993728/5047652781'
};

const LOCAL_TOKEN_NAME = 'VLinkJobs';

const IMAGE_ROUTES = {
    navImage: '/assets/vlink-banner.jpg',
    mainLogo: '/assets/VLinkMainLogo.png',
    altLogo: '/assets/VLinkAltLogo.png',
    
}

export const imgdefault = "/assets/nophoto.png"

export const ApplicationStatus=[{id:0,status:"New", style: "bg-blue-800 text-white rounded-md p-2 w-28"},
  {id:1,status:"Shortlisted", style: "bg-green-800 text-white rounded-md p-2 w-28"},
  {id:2,status:"Rejected", style: "bg-red-800 text-white rounded-md p-2 w-28"},]

export { API_BASE_URL, IMAGE_ROUTES, ADSENSECLIENTID, ADSENSESLOTS, PAYMENT_GATEWAY_KEY_ID, PAYMENT_GATEWAY_KEY_SECRET, API_STATIC_URL, LOCAL_TOKEN_NAME, RECAPCHASECRETKEY, RECAPCHASITEKEY};