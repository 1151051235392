//completed
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {API_STATIC_URL, imgdefault,} from "../../apiConfig";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useGetAppliedJobsMutation } from "../../redux/slice/jobServiceApiSlice";

const CandidateApplications = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [appliedJobs, { data: getAppliedJobs }] = useGetAppliedJobsMutation();
  const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
  const pageSize = 10;
  useEffect(() => {
    appliedJobs({ profileId: currentUser?.refId, pageSize, pageNumber: 1 });
  }, [currentUser?.refId,appliedJobs,pageSize]);

  useEffect(() => {
    if (getAppliedJobs?.dataCount) {
        setTotalPages(Math.ceil(getAppliedJobs.dataCount / pageSize));
    }
}, [getAppliedJobs, pageSize]);


  const goToJobListing = (jobId) => {
    navigate(`/JobDetails/${jobId}`);
  };

  const backToProfile = () => {
    navigate("/UserProfile");
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    appliedJobs({ profileId: currentUser?.refId,pageNumber,pageSize});
  };

  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Applied Jobs</title>
        <link
          rel="canonical"
          href="https://vlinkjobs.com/CandidateApplications"
        />
      </Helmet>
        <div className=" flex flex-col font-custom-font md:flex-row mt-10 w-full items-center py-5 md::px-2 min-h-screen">
        <div className="my-4 flex items-center gap-2 sm:hidden lg:block ">
            <div className="w-[350px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex  flex-col w-full font-custom-font items-center gap-4 mt-5">
            <h1 className="font-bold md:text-3xl sm:text-xl mb-4">
              Your Job Applications
            </h1>
            {getAppliedJobs?.result?.length === 0 && (
              <div className="font-semibold md:text-xl ">
                You have not applied to any jobs yet.
              </div>
            )}
            {getAppliedJobs?.result?.length > 0 &&
              getAppliedJobs?.result?.map((item, index) => (
                <><div
                  key={index}
                  onClick={() => goToJobListing(item.jobId)}
                  className="relative w-full rounded-lg border-2 shadow-lg bg-white border-gray-200 transition ease-in-out transform hover:scale-105"
                >
                  <div className="flex flex-col gap-4 justify-between text-nowrap">
                    <div className="font-bold md:text-xl text-gray-800  flex flex-row justify-between items-center mx-4">
                      {item.jobTitle}
                      <span className="text-sm text-gray-600"> {item.categoryName} </span>
                    </div>
                    <div className="flex flex-wrap gap-3 items-center justify-between text-sm text-gray-600 mx-4">
                      <div className="flex flex-row gap-1 items-center justify-start">
                        <img className="md:h-10 md:w-10 sm:h-6 sm:w-6 m-2 md:ml-0"
                          src={`${item.imageUrl ? `${API_STATIC_URL}${item.imageUrl}` : `${imgdefault}`}`}
                          alt="" />
                        <span className="flex flex-row mr-3 font-bold md:text-xl md:justify-center  md:ml-0 md:items-center">
                          {" "}
                          {item.companyName}
                        </span>
                      </div>
                      <span className="flex flex-row mx-3 md:justify-center md:items-center">
                        <FaLocationDot className="mx-2" /> {item.location}
                      </span>
                      <span className="flex flex-row mx-3 md:justify-center md:items-center">
                        <FaMoneyBillWave className="mx-2" /> {item.salary}
                      </span>
                      <span className="flex flex-row mx-3 md:justify-center md:items-center">
                        <FaBriefcase className="mx-2" />{" "}
                        {item.minExperience} to {item.maxExperience} yrs
                      </span>
                    </div>

                  </div>
                </div><div className="flex gap-2 mt-5">
                    {generatePaginationButtons().map((button, index) => (
                      <button
                        key={index}
                        className={`rounded-lg bg-blue-400 px-2 font-semibold  border hover:scale-110 transition border-blue-400 hover:bg-blue-500 ${button === "..." ? "opacity-0" : ""} ${currentPage === button
                            ? "bg-blue-600 text-white"
                            : "text-black"}`}
                        onClick={() => typeof button === "number"
                          ? handlePageChange(button)
                          : null}
                      >
                        {button}
                      </button>
                    ))}
                  </div></>
              ))}
            <button className="rounded-md font-semibold text-white bg-gray-800 mt-8 px-6 py-2"
              onClick={backToProfile}> Back </button>
          </div>
          <div className="my-4 flex items-center sm:hidden lg:block ">
            <div className="w-[350px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
    </>
  );
};

export default CandidateApplications;
