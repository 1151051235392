//completed
import { API_STATIC_URL, imgdefault } from "../../apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaMoneyBillWave } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaBriefcase } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { GrDocumentUpload } from "react-icons/gr";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useParams } from "react-router-dom";
import {
  useApplyJobMutation,
  useGetCandidateProfileQuery,
  useGetJobByIdQuery,
} from "../../redux/slice/jobServiceApiSlice";
import ReactHtmlParser from 'html-react-parser';


const JobDetails = () => {
  const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
  const { jobId } = useParams();
  const { data } = useGetJobByIdQuery(jobId, { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const { data: getCandidateProfile } = useGetCandidateProfileQuery(
    currentUser?.refId
  );
  const [applyJob] = useApplyJobMutation();
  const navigate = useNavigate();
  const handleApply = async () => {
    if (currentUser?.token) {
      if (currentUser?.userRoleId === 2) {
        toast.error("This is not a JobSeeker Account", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      } else if (getCandidateProfile?.result) {
        if (
          getCandidateProfile?.result?.isEmailVerify === 0 ||
          getCandidateProfile?.result?.isMobileVerify === 0 ||
          getCandidateProfile?.result?.profileAttachment == null ||
          getCandidateProfile?.result?.fullName == null ||
          getCandidateProfile?.result?.experience == null
        ) {
          toast.error("Please complete setting up your profile", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          navigate("/UserProfile");
        } else {
          const payloadData = {
            profileId: getCandidateProfile?.result?.id,
            jobId,
            status: 0,
          };
          const res = await applyJob(payloadData);
          if (res.data.result == null) {
            toast.error(res.data.message, {
              autoClose: 1000,
              style: {
                width: "300px",
              },
            });
          } else {
            toast.success("Application Submitted", {
              autoClose: 1000,
              style: {
                width: "300px",
              },
            });
            navigate("/JobSearch");
          }
        }
      }
    } else {
      toast.error("Login to your account first", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      navigate("/Login/3");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Job Description</title>
        <link rel="canonical" href="https://vlinkjobs.com/JobDetails" />
      </Helmet>
      <div className="flex md:flex-row sm:flex-col font-custom-font justify-between md:px-2 mt-20 bodybg w-full mb-4">
        <div className="flex items-center">
          <div className="">
            <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
          </div>
        </div>
        {data?.result && (
          <div className="flex flex-col lg:w-3/5 sm:w-full md:m-4 p-4 border-2 rounded-lg shadow-lg bg-white">
            <div className="flex justify-between mb-6">
              <div>
                <h1 className="md:text-3xl sm:text-xl mr-10 font-bold text-nowrap">
                  {data?.result?.jobTitle}
                </h1>
              </div>
            </div>
            <hr className="border border-blue-300  mb-5" />
            <div className="flex gap-1 flex-wrap justify-between items-center mb-6">
              <div className="flex gap-2 items-center">
              <img className="md:h-10 md:w-10 sm:h-6 sm:w-6 m-2 md:ml-0"
                    src={`${data?.result?.imageUrl ? `${API_STATIC_URL}${data?.result?.imageUrl}` : `${imgdefault}`}`}
                    alt="" />
                <div className="flex flex-col">
                  <p className="md:text-lg sm:text-sm font-semibold">
                    {data?.result?.companyName}
                  </p>
                  <div className="flex items-center sm:text-sm">
                    <HiOfficeBuilding className="mr-2" /> Location:
                    <span className="text-gray-700 w-32">
                      {data?.result?.location}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 sm:text-sm">
              <div className="flex gap-2 items-center">
                  <span className="font-semibold"> Category: </span> {data?.result?.categoryName}
              </div>
              <div className="flex gap-2 items-center justify-end">
                <span className="font-semibold"> Posted On: </span> {data?.result?.datePosted}
              </div>
              <div className="flex gap-2 items-center"><FaMoneyBillWave />
                  <span className="font-semibold"> Salary: </span> {data?.result?.salary}
              </div>
              <div className="flex gap-2 items-center justify-end"><FaBriefcase />
                <span className="font-semibold"> Experience: </span> {data?.result?.minExperience} to{" "}
                {data?.result?.maxExperience} years
              </div>
              {data?.result?.isDisplayEmail === 1 &&<div className="flex items-center gap-2">
                <MdEmail /><span className="font-semibold"> Email: </span> {data?.result?.email}
              </div>}
              {data?.result?.isDisplayMobile === 1 && <div className="flex gap-2 items-center justify-end">
                <FaPhoneAlt /><span className="font-semibold"> Contact No: </span> {data?.result?.mobile}
              </div>}
            </div>
            <p className=" flex flex-row items-start font-semibold text-xl my-4 sm:text-sm">
              Description:
            </p>
            <div className="font-normal text-sm text-gray-600 mb-4 ">
            {ReactHtmlParser(data?.result?.jobDescription)}
            </div>
            <div className="flex justify-center gap-5 items-end h-full my-4">
            <button className="rounded-md font-semibold text-white bg-gray-800 mt-8 px-6 py-2 w-32"
              onClick={() => navigate(-1)}> Back </button>

              {(currentUser?.userRoleId !== 1 &&
                currentUser?.userRoleId !== 2) && (
                  <button className="flex rounded-md font-semibold text-white bg-blue-800 mt-8 px-6 py-2 w-32"
              onClick={handleApply}> Apply Now </button>
                )}
            </div>
          </div>
        )}

        <div className=" flex items-center">
          <div className="">
            <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
