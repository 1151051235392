import { useParams } from "react-router-dom/dist"
import CreateJobForm from "../CreateJob/CreateJobForm";
import { useGetJobByIdQuery } from "../../redux/slice/jobServiceApiSlice";


const UpdateJob = () => {
    const {id}=useParams()
  const { data,isLoading } = useGetJobByIdQuery(id);
  let sta 
  if(isLoading){
    sta=<p className="my-40 mx-20 text-white text-lg">Loading....</p>
  }
  if(data?.result){
    sta=<CreateJobForm type="Update" editJob={data?.result}/>
  }
  return (<>
    {sta}
    </>)
}

export default UpdateJob