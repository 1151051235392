//completed
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { useGetJobCategoriesQuery } from '../../redux/slice/jobServiceApiSlice';
import { createSearchParams } from 'react-router-dom';

const NavSearch = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const {data:categories}=useGetJobCategoriesQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'category_id') {
            setSelectedCategory(value);
        } else if (name === 'title') {
            setTitle(value);
        }
    };

    const goToJobSearch = () => {
        navigate({
            pathname: '/JobSearch',
            search: `?${createSearchParams({ catId: selectedCategory,jobTitle:title })}`,
          });
       
    };

    return (
        <div className='text-center sm:flex sm:flex-col py-2 grid'>
            <div className='flex flex-row items-center justify-center mt-3'>
                <div className='flex md:flex-row sm:flex-col sm:items-center gap-2'>
                    <span className='font-semibold text-md'>Search Jobs</span>
                    <input type='text' placeholder='Enter Job Title' value={title} name="title" className='h-10 md:w-[400px] max-md:w-64 relative block px-3 py-2 border border-black placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black' onChange={handleChange} />
                    <select id="categorySelect" children className='h-10 w-64 relative block px-3 py-2 border border-black placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black' name="category_id" value={selectedCategory} onChange={handleChange}>
                        <option className='text-black' hidden value="">Select a category</option>
                        {categories?.result?.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.categoryName}
                            </option>
                        ))}
                    </select>
                    <button onClick={goToJobSearch} className=' bg-red-800 h-10 flex items-center w-12 text-white font-bold py-4 px-4 rounded-lg'><FaSearch /></button>
                </div>
            </div>
        </div>
    )
}

export default NavSearch;
