//completed
//import Cards from "./Cards";
import { ADSENSESLOTS, API_STATIC_URL, imgdefault } from "../../apiConfig";
import Slides from "./Slides";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import {
  useGetHomepageJobsQuery,
  useGetJobCategoriesQuery,
} from "../../redux/slice/jobServiceApiSlice";
import { Link } from "react-router-dom";
import AdsComponent from "../GlobalComp/AdsComponent";

const BrowsingJobs = () => {
  const { data: homeJobs } = useGetHomepageJobsQuery("jobsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });


  return (
    <div className="sm:w-full bg-white py-2">
      <div className="md:ml-2 md:mr-2 mb-6">
        <div className="items-center md:mr-14 md:ml-14 sm:ml-1 sm:mr-1">
          <h1 className="md:text-2xl mb-4 ml-2 md:font-bold sm:text-lg sm:font-semibold">
            Featured Categories
          </h1>
          <div className="grid grid-cols-1 sm:text-sm sm:w-full md:grid-cols-3 gap-4 mr-10">
            {categories?.result?.length > 0 &&
              categories?.result?.map((category) => (
                <Link
                  key={category.id}
                  className="flex justify-between border-2 border-blue-900 text-blue-800 rounded-lg mx-1 mb-1 transition duration-300 text-lg px-4 ease-in-out transform hover:scale-105 hover:bg-blue-800 hover:text-white shadow-md"
                  to={`/JobSearch?catId=${category.id}`}
                >
                  {category.categoryName}
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row w-full gap-4 flex-col mt-2 sm:px-4 py-6">
      <div className="flex flex-col lg:w-[420px] sm:w-full px-2">
          <img src="./assets/vlinkad1.png" alt="" className="rounded-lg hidden lg:block mt-10 mb-2"></img>
          <img src="./assets/studentproj.png" alt="" className="rounded-lg hidden lg:block mt-4"></img>
      </div>
        <div className="flex flex-col lg:w-full px-2">
          <h1 className="md:text-2xl mb-2 md:font-bold sm:text-lg sm:font-semibold">
            Recent Jobs
          </h1>
          <div className="grid grid-cols-1 gap-4">
            {homeJobs?.result?.length > 0 &&
              homeJobs?.result?.map((item, index) => (
                <Link key={item.jobId}
                  to={`/JobDetails/${item.jobId}`}
                  className="md:h-40 sm:w-full rounded-lg shadow-lg bg-white border border-gray-200 hover:border-gray-300 transition ease-in-out transform hover:scale-105"
                >
                  <div className="flex md:flex-row sm:flex-col md:justify-between">
                    <div className="flex md:flex-row w-full md:items-center sm:flex-col m-2">
                      <div className="flex sm:flex-col">
                        <div className="font-bold md:text-2xl text-gray-800 md:my-3 flex flex-row gap-2 items-center ml-4">
                          {item.jobTitle}
                        </div>
                        <div className="flex md:flex-row sm:flex-col md:items-center sm:text-sm text-gray-600 ml-4">
                          <div className="flex flex-row items-center justify-start">
                            <img className="md:h-8 md:w-8 sm:h-6 sm:w-6 m-2 md:ml-0"
                              src={`${item.imageUrl ? `${API_STATIC_URL}${item.imageUrl}`: `${imgdefault}`}`}
                              alt="" />
                            <span className="flex flex-row mr-3 md:justify-center md:text-xl font-semibold md:ml-0 md:items-center">
                              {" "}
                              {item.companyName}
                            </span>
                          </div>
                          </div>
                        <div className="flex md:flex-row sm:flex-col md:items-center sm:text-sm text-gray-600 mt-2">
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaLocationDot className="mx-2" /> {item.location}
                          </span>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaMoneyBillWave className="mx-2" /> {item.salary}
                          </span>
                          <span className="flex flex-row mx-3 md:justify-center md:items-center">
                            <FaBriefcase className="mx-2" />{" "}
                            {item.minExperience} to {item.maxExperience} yrs
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex sm:justify-start sm:ml-10 items-center md:w-1/5 ">
                      <div className="text-sm  text-gray-600">
                        {item.categoryName}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <div className="max-w-[400px] flex flex-col">
          <div className="flex flex-col gap-2">
          <h1 className="md:text-2xl md:font-bold sm:text-lg sm:font-semibold sm:mt-4 md:mt-0 ml-4">
            Featured Jobs
          </h1>
          <Slides/>
          <img src="./assets/online_course.png" alt="" className="rounded-lg hidden lg:block h-60 w-[380px]"></img>
          </div>
          </div>
        </div>
        <div className="flex w-full max-md:hidden">
            <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
          </div>
      </div>
  );
};

export default BrowsingJobs;
