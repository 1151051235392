//completed
import React, { useEffect, useState } from "react";
import { IMAGE_ROUTES, RECAPCHASITEKEY } from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import {
  useRegisterCandidateMutation,
  useRegisterRecruiterMutation,
} from "../../redux/slice/userServiceApiSlice";
import Toggle from "../GlobalComp/ToggleButton";
import { Link } from "react-router-dom";
import { useGetJobCategoriesQuery } from "../../redux/slice/jobServiceApiSlice";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [fullNameEmpty, setFullNameEmpty] = useState(false);
  const [mobileEmpty, setMobileEmpty] = useState(false);
  const [addressEmpty, setAddressEmpty] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [companyExpEmpty, setCompanyExpEmpty] = useState(false);
  const [profilePicEmpty, setProfilePicEmpty] = useState(false);
  const [catIdEmpty, setCatIdEmpty] = useState(false);
  const [companyExp, setCompanyExp] = useState("");
  const [categoryId, setCategoryId] = useState(3);
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [registerCandidate] = useRegisterCandidateMutation();
  const [registerRecruiter] = useRegisterRecruiterMutation();
  const [toggle, setToggle] = useState(false);
  const { uid } = useParams()
  useEffect(() => {
    if (Number(uid) === 2) {
      setToggle(true)
    }
  }, [uid])

  const handleToggleChange = () => {
    setToggle(!toggle);
    setCompanyExp("")
  };

  const handleVerify = () => {
    setIsVerified(pre => !pre);
  };

  const handleToggleVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicPreview(reader.result);
        const base64String = reader.result.split(",")[1];

        // Now you can use the base64String as needed, such as storing it in state or sending it to the server
      };
      reader.readAsDataURL(file);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        // Correct assignment of onload event
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        // Correct assignment of onerror event
        reject(error);
      };
    });
  };
  const submitClicked = async () => {
    const today = new Date();
    const todayFormatted =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();

    // Check if all required fields are filled
    if (email === "") setEmailEmpty(true);

    if (password === "") setPasswordEmpty(true);

    if (confirmPassword === "") setConfirmPasswordEmpty(true);

    if (fullName === "") setFullNameEmpty(true);
    if (mobile === "") setMobileEmpty(true);
    if (companyExp === "") setCompanyExpEmpty(true);
    if (address === "") setAddressEmpty(true);
    // if(profilePic === null)setProfilePicEmpty(true);
    else {
      setAddressEmpty(false);
      setCompanyExpEmpty(false);
      setFullNameEmpty(false);
      setPasswordEmpty(false);
      setConfirmPasswordEmpty(false);
      setEmailEmpty(false);
      setMobileEmpty(false);
    }

    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      fullName === "" ||
      mobile === "" ||
      address === "" ||
      companyExp === "" ||
      companyExp === null
    ) {
      toast.error("Please enter all fields", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else if (password !== confirmPassword) {
      toast.error("Check the password!", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else if (mobile.replace(/\D/g, "").length !== 10) {
      setMobileEmpty(false);
      setMobileEmpty(true);
      toast.error("Mobile Number must be 10 digits", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else if (!emailFormat.test(email)) {
      setEmailEmpty(true);
      toast.error("Please enter a valid email address", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else {
      setEmailEmpty(false);
      // Convert profile pic to base64
      var base64String = null;
      if (profilePic) {
        base64String = await convertBase64(profilePic);
      }

      // Prepare data to be sent
      const data = {
        mobile: mobile,
        fullName: fullName,
        address: address,
        email: email,
        userPassword: password,
        regDate: todayFormatted,
        image: base64String ? base64String : "",
      };
      try {
        // Send data based on selected user type
        let res;
        if (!toggle) {
          if (companyExp === null) {
            setCatIdEmpty(true)
            return;
          }
          setCatIdEmpty(false)
          data.experience = companyExp;
          data.categoryId = categoryId
          res = await registerCandidate(data);
        } else {
          data.companyName = companyExp;
          res = await registerRecruiter(data);
        }
        if (res.data.success === true) {
          toast.success(res.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          navigate(`/Login/${toggle ? 2 : 3}`);
        } else {
          toast.error(res.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.error("Registration error:", error);
        toast.error("Failed to register. Please try again later.", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    }
  };

  const handleMobileChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1$2$3"
    ); // Format the mobile number (e.g., XXX-XXX-XXXX)
    setMobileEmpty(false)
    setMobile(formattedValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Register</title>
        <link rel="canonical" href="https://vlinkjobs.com/Register" />
      </Helmet>
      <div className="">
        <div className="bodybg min-h-screen flex md:flex-row sm:flex-col items-center gap-2 justify-between  font-custom-font py-4 md:px-2">
          <div className=" flex justify-start items-center sm:hidden md:block">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="w-1/2 sm:w-full md:w-1/2 space-y-2 bg-blue-200 border border-blue-400 hover:border-blue-600 px-4 py-2 rounded-2xl">
            <div className="flex flex-col items-center">
              <div className="flex justify-center">
                {" "}
                {/* Centering the logo horizontally */}
                <Link className="flex justify-center items-center" to="/">
                  <img
                    src={`${IMAGE_ROUTES.altLogo}`}
                    alt="Logo"
                    className="max-h-28"
                  />
                </Link>
              </div>

              <div className="flex flex-row items-center justify-center">
                {" "}
                {/* Using justify-between to push Upload section to the end */}
                <h2 className="text-2xl font-extrabold text-blue-900">
                  Register Now!
                </h2>
              </div>
              <div className=" flex flex-col items-center  rounded-full overflow-hidden min-h-28 justify-center min-w-36">
                {" "}
                {/* Profile picture upload section */}
                <label htmlFor="profilePic" className="cursor-pointer">
                  {profilePicPreview /* Show the preview if profile picture is selected */ ? (
                    <img
                      src={profilePicPreview}
                      alt="Profile Preview"
                      className="mt-2 w-24 h-24 rounded-full"
                    />
                  ) : (
                    /* Show default message if no profile picture is selected */
                    <div className="flex flex-col items-center">
                      <span className="text-sm font-medium text-blue-900 dark:text-white mb-2">
                        {!toggle ? "Upload Profile Picture" : "Upload Logo"}
                      </span>
                      <span
                        className={`bg-blue-400 px-3 py-2 rounded-lg text-gray-900 hover:bg-blue-300 ${profilePicEmpty
                          ? "border border-red-400 animate-shake"
                          : ""
                          } hover:scale-110 transition ease-in-out cursor-pointer`}
                      >
                        Choose File
                      </span>
                    </div>
                  )}
                </label>
                <input
                  id="profilePic"
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePicChange}
                  className="hidden" /* Hide the input element visually */
                />
              </div>
              <div>
                <Toggle
                  toggle={toggle}
                  handleToggleChange={handleToggleChange}
                />
              </div>
            </div>

            <form className="mt-8 grid md:grid-cols-2 grid-cols-1 gap-4">
              <div className="space-y-3">
                {toggle && <div>
                  <p className="block mb-2 mt-2 text-sm font-medium text-blue-900 dark:text-white">
                    Company Name
                  </p>
                  <input
                    id="experience"
                    type="text"
                    min="0"
                    max="40"
                    value={companyExp}
                    onChange={(e) => { setCompanyExp(e.target.value); setCompanyExpEmpty(false) }}
                    className={`relative block w-full px-3 py-2 border ${companyExpEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Company Name"
                  />
                  {companyExpEmpty && <p className="text-red-600 text-sm">*Enter Company Name</p>}
                </div>}
                <div>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Full Name
                  </p>
                  <input
                    id="name"
                    value={fullName}
                    onChange={(e) => { setName(e.target.value); setFullNameEmpty(false) }}
                    className={`relative block w-full px-3 py-2 border ${fullNameEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Name"
                  />
                  {fullNameEmpty && <p className="text-red-600 text-sm">*Enter Full Name</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Your Email
                  </p>
                  <input
                    id="email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); setEmailEmpty(false) }}
                    className={`relative block w-full px-3 py-2 border ${emailEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Email Address"
                  />
                  {emailEmpty && <p className="text-red-600 text-sm">*Enter Valid Email Address</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Your Mobile No
                  </p>
                  <input
                    id="mobile"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    placeholder="Enter Your Mobile no"
                    className={`relative block w-full px-3 py-2 border mb-2 ${mobileEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    value={mobile}
                    onChange={handleMobileChange}
                  />
                  {mobileEmpty && <p className="text-red-600 text-sm">*Enter Valid Mobile Number</p>}
                </div>
                {!toggle && <div>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Select the category
                  </p>
                  <select
                    id="categorySelect"
                    value={categoryId}
                    children
                    className={`relative block w-full px-3 py-2 border mb-2 ${catIdEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`} name="category_id"
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option className='text-black' hidden value="">Select a category</option>
                    {categories?.result?.length > 0 && categories?.result?.map((category) => (
                      <option
                        key={category.id}
                        value={category.id}
                      >
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                  {catIdEmpty && <p className="text-red-600 text-sm">*Select valid category</p>}
                </div>}
                {!toggle && <div>
                  <p className="block mb-2 mt-2 text-sm font-medium text-blue-900 dark:text-white">Your Work Experience
                  </p>
                  <input
                    id="experience"
                    type="number"
                    min="0"
                    max="40"
                    value={companyExp}
                    onChange={(e) => { setCompanyExp(e.target.value); setCompanyExpEmpty(false) }}
                    className={`relative block w-full px-3 py-2 border ${companyExpEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    placeholder="Enter Your Experience"
                  />
                  {companyExpEmpty && <p className="text-red-600 text-sm">*Enter Your Experience</p>}
                </div>}
              </div>

              <div className={` ${!toggle && `space-y-3`} space-y-2`}>
                <div className={`flex flex-col ${!toggle && `md:h-[218px]`} h-1/2 mb-2`}>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Your Address
                  </p>
                  <textarea
                    id="address"
                    value={address}
                    onChange={(e) => { setAddress(e.target.value); setAddressEmpty(false) }}
                    className={`relative block w-full h-full bg-gray-150 px-3 border ${addressEmpty
                      ? "border-red-400 animate-shake"
                      : "border-gray-300"
                      } placeholder-gray-500 py-1 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mb-2 hover:border-black`}
                    placeholder="Enter Your Address"
                  />
                  {addressEmpty && <p className="text-red-600 text-sm">*Enter Address Details</p>}
                </div>
                <div>
                  <p className="block mb-2 text-sm font-medium text-blue-900 dark:text-white">
                    Enter Password
                  </p>
                  <div className="flex flex-row">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => { setPassword(e.target.value); setPasswordEmpty(false) }}
                      className={`relative block w-full px-3 py-2 border ${passwordEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                        } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter a strong password"
                    />
                    {/* Toggle visibility button */}
                    <button
                      onClick={handleToggleVisibility}
                      className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                    >
                      <FaEye />
                    </button>
                  </div>
                  {passwordEmpty && <p className="text-red-600 text-sm">*Enter Password</p>}
                </div>
                <div>
                  <p className="block mb-2 mt-2 text-sm font-medium text-blue-900 dark:text-white">
                    Confirm Password
                  </p>
                  {/* Confirm password input field */}
                  <div className="flex flex-row">
                    <input
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordEmpty(false) }}
                      className={`relative block w-full px-3 py-2 border ${confirmPasswordEmpty
                        ? "border-red-400 animate-shake"
                        : "border-gray-300"
                        } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Confirm Password"
                    />
                    <button
                      onClick={handleToggleVisibility}
                      className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                    >
                      <FaEye />
                    </button>
                  </div>
                  {confirmPasswordEmpty && <p className="text-red-600 text-sm">*Enter valid Confirm Password</p>}
                </div>
              </div>
            </form>
            <div className="flex flex-col justify-center items-center pt-2">

              <div className="relative w-1/2 flex flex-col gap-2 items-center mb-2">
                <ReCAPTCHA
                  sitekey={RECAPCHASITEKEY}
                  size="normal"
                  onChange={handleVerify}
                />
                <button disabled={!isVerified} onClick={submitClicked} type="submit"
                  class="text-white bg-green-800 hover:bg-green-600 focus:outline-none text-md rounded-full px-3 py-2 text-center me-2 w-full">
                  Register </button>
              </div>
              <div className="flex flex-row min-w-fit justify-center mt-2">
                <div>Already have an account? </div>
                <Link className="ml-2 hover:text-gray-700" to={`/Login/${toggle ? 2 : 3}`}>
                  <b>Sign In</b>
                </Link>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center max-md:hidden">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
