import { useEffect, useState } from "react"
import { useAdminJobsForApprovalMutation } from "../../redux/slice/jobServiceApiSlice"
import CardComponent from "./Jobs/CardComponent"


const JobsForApproval = () => {
    const [adminJobsForApprovalS,{data:adminJobsForApproval,isLoading}]  =useAdminJobsForApprovalMutation()
    const [pageNumber,setPageNumber]=useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 10;
    useEffect(()=>{
        setTotalPages(Math.ceil(adminJobsForApproval?.dataCount/pageSize))
    },[adminJobsForApproval?.dataCount,pageSize])
    useEffect(()=>{
        adminJobsForApprovalS({pageNumber,pageSize})
    },[pageNumber,pageSize,adminJobsForApprovalS])

    const handlePageChange = async (pageNumber) => {
        setPageNumber(pageNumber);
      };
    const generatePaginationButtons = () => {
        const buttons = [];
    
        if (totalPages <= 5) {
          for (let i = 1; i <= totalPages; i++) {
            buttons.push(i);
          }
        } else {
          let startPage = Math.max(1, pageNumber - 2);
          let endPage = Math.min(pageNumber + 2, totalPages);
    
          if (endPage === totalPages) {
            startPage = Math.max(1, totalPages - 4);
          } else if (startPage === 1) {
            endPage = Math.min(5, totalPages);
          }
    
          if (startPage > 1) {
            buttons.push(1);
            if (startPage > 2) {
              buttons.push("...");
            }
          }
    
          for (let i = startPage; i <= endPage; i++) {
            buttons.push(i);
          }
    
          if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
              buttons.push("...");
            }
            buttons.push(totalPages);
          }
        }
        return buttons;
      };

      let content 
      if(isLoading) content = <div className=" text-blue-500 font-bold text-xl ">Loading....</div>
      if(adminJobsForApproval?.result.length>0){
        content = <div className=" px-2 text-blue-500 font-bold text-xl h-full">
            <div className=" flex flex-col gap-4">
                <div className=" flex flex-col gap-2 font-custom-font">
                {adminJobsForApproval?.result.map(jobaprove=><CardComponent job={jobaprove} key={jobaprove.jobId} type="Approve"/>)}
                </div>
            <div className="flex gap-2 mt-5 px-2">
            {generatePaginationButtons().map((button, index) => (
                    <button
                      key={index}
                      className={`rounded-lg bg-blue-400 px-3 py-1 text-sm font-semibold  border hover:scale-110 transition border-blue-400 hover:bg-blue-500 ${
                        button === "..." ? "opacity-0" : ""
                      } ${
                        pageNumber === button
                          ? "bg-blue-600 text-white"
                          : "text-black"
                      }`}
                      onClick={() =>
                        typeof button === "number"
                          ? handlePageChange(button)
                          : null
                      }
                    >
                      {button}
                    </button>
                  ))}
            </div>
            </div>
        </div>
      }
      if(adminJobsForApproval?.result.length===0){
        content = <div className="px-2 text-blue-500 font-bold text-xl">No New Jobs For Approval</div>
      }
  return <>
  {content}
  </>
}

export default JobsForApproval