import {  useNavigate } from "react-router-dom";
import { FaBriefcase, FaPhoneAlt } from "react-icons/fa";
import { useGetJobCategoriesQuery } from "../../../redux/slice/jobServiceApiSlice";
import { useGetCategoryProfilesMutation, useJobApplicantsMutation, useGetRecruiterQuery } from "../../../redux/slice/recruiterServiceApiSlice";
import { toast } from "react-toastify";
import { API_STATIC_URL, imgdefault } from "../../../apiConfig";
import { useEffect, useState } from "react";
import { MdEmail } from "react-icons/md";
import { useParams } from "react-router-dom";

const AdminViewProfiles = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentUser = JSON.parse(localStorage.getItem("vlinks"));
    const { id } = useParams();
    const [jobAppl, { data: jobApplicants }] = useJobApplicantsMutation();
    const [getCategoryProfiles, { data: candidatesProfile }] = useGetCategoryProfilesMutation();
    const { data: categories, isSuccess, isLoading } = useGetJobCategoriesQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true });
    const navigate = useNavigate();
    const [catId, setCatId] = useState(1);
    const pageSize =10;
    useEffect(() => { 
      getCategoryProfiles({ catId }); 
    }, [catId, getCategoryProfiles]);


    useEffect(() => {
      if (jobApplicants?.dataCount) {
        setTotalPages(Math.ceil(jobApplicants.dataCount / pageSize));
      }
    }, [jobApplicants, pageSize]);

    const viewResume = (id)=>{
        const url = `/admindash/adminviewprofiledetails/${id}`;
        // Open the URL in a new tab
        const baseUrl = window.location.origin;
        window.open(`${baseUrl}${url}`, '_blank');
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-full wrapper">
                <p>Loading...........</p>
            </div>
        );
    }

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        jobAppl({
          jobId: id,
          pageNumber,
          pageSize,
        });
      };
      const generatePaginationButtons = () => {
        const buttons = [];
    
        if (totalPages <= 5) {
          for (let i = 1; i <= totalPages; i++) {
            buttons.push(i);
          }
        } else {
          let startPage = Math.max(1, currentPage - 2);
          let endPage = Math.min(currentPage + 2, totalPages);
    
          if (endPage === totalPages) {
            startPage = Math.max(1, totalPages - 4);
          } else if (startPage === 1) {
            endPage = Math.min(5, totalPages);
          }
    
          if (startPage > 1) {
            buttons.push(1);
            if (startPage > 2) {
              buttons.push("...");
            }
          }
    
          for (let i = startPage; i <= endPage; i++) {
            buttons.push(i);
          }
    
          if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
              buttons.push("...");
            }
            buttons.push(totalPages);
          }
        }
        return buttons;
      };

    return (
        isSuccess && (
            <div className="flex md:flex-row flex-col w-full gap-4 pt-2 md:justify-between">
                <div className="shadow-xl border-r-2 border-gray-200 md:w-[250px] w-full p-3 py-4">
                    <span level="h6" component="p" className="text-lg font-semibold">Candidate Categories:</span>
                    <div className="flex flex-col gap-3 mt-4">
                        {categories?.result?.length > 0 && categories?.result?.map(category => (
                            <button
                                variant={catId === category.id ? "solid" : "outlined"}
                                onClick={() => setCatId(category.id)}
                                key={category.id}
                                className="font-medium text-sm"
                            >
                                {category.categoryName}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center mx-auto px-4">
                    {candidatesProfile?.result?.length <= 0  || candidatesProfile?.result ==null  ? (
                        <div level="h5" className="my-3 flex justify-center w-full mx-auto font-bold">
                            No Candidates Under {categories?.result?.filter(category => category.id === catId)[0].categoryName}
                        </div>
                    ) : (
                        <div className="w-full">
                            {candidatesProfile?.result?.map((item) => (
                            <div
                                key={item.applicationId}
                                className="flex flex-col gap-4 overflow-hidden px-3 mt-2 rounded-lg shadow-lg bg-white border border-gray-200"
                            >
                                <div className="flex flex-wrap justify-between">
                                <div className="flex flex-row gap-4 items-center">
                                    <img
                                    className="h-10 w-10 sm:mt-2"
                                    src={`${item.imageUrl ? `${API_STATIC_URL}${item.imageUrl}`: `${imgdefault}`}`}
                                    alt=""
                                    />
                                    <p className="font-bold text-xl">
                                    {" "}
                                    {item.fullName}
                                    </p>
                                </div>
                                <span className="text-sm text-gray-600">
                                        {item.categoryName}
                                        </span>
                                </div>
                                <div className="flex md:flex-row flex-wrap justify-between items-center w-full gap-8 text-nowrap">
                                    <div className="flex md:flex-row items-center md:justify-center text-sm">
                                        <FaPhoneAlt className="mr-1"/> Mobile:
                                        <span>
                                        {item.mobile}
                                        </span>
                                    </div>
                                    <span className="flex flex-row items-center text-sm">
                                        <MdEmail className="mr-1" />{"Email: "}
                                        <span>{item.email}</span>
                                    </span>
                                    <span className="flex flex-row items-center text-sm">
                                        <FaBriefcase className="mr-1" />{"Experience: "}
                                        <span>
                                        {item.experience} yrs
                                        </span>
                                    </span>
                                </div>

                                <div className="flex flex-row pb-2 items-center gap-10 justify-between text-gray-600">
                                    <button
                                    className="bg-blue-800 text-white text-sm font-normal p-2 ml-2 rounded-lg"
                                    onClick={() =>
                                        viewResume(item.id)
                                    }
                                    >
                                    View Profile
                                    </button>
                                </div>
                            </div>
                        ))}
                        {candidatesProfile?.result?.length > 0 && (
              <div className="flex mt-5 gap-2">
                {generatePaginationButtons().map((button, index) => (
                  <button
                    key={index}
                    className={`rounded-lg px-2 font-semibold  border hover:scale-110 transition border-blue-400 hover:bg-blue-500 ${
                      button === "..." ? "opacity-0" : ""
                    } ${
                      currentPage === button
                        ? "bg-blue-600 text-white"
                        : "text-black"
                    }`}
                    onClick={() =>
                      typeof button === "number"
                        ? handlePageChange(button)
                        : null
                    }
                  >
                    {button}
                  </button>
                ))}
              </div>
            )}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default AdminViewProfiles;
