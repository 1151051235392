import React from 'react'
import SideNavBar, { TopNavBar } from './SideNavBar'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <>
    <TopNavBar/>
    <Outlet/>
    </>
  )
}
export const SubLayout = () => {
  return (
    <div className='flex md:flex-row flex-col max-md:w-full'>
    <SideNavBar/>
    <div className='w-full h-full pt-24 md:pl-60 max-md:pt-56 px-2 pb-4 '>
    <Outlet/>
    </div>
    </div>
  )
}

export default Layout