import { useParams } from "react-router-dom/dist"

import CreateJobFormAdmin from "./CreateJobForm";
import { useGetJobByIdQuery } from "../../../redux/slice/jobServiceApiSlice";


const UpdateJobAdmin = () => {
    const {id}=useParams()
  const { data,isLoading } =useGetJobByIdQuery(id,{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true});
  let content 
  if(isLoading){
    content=<p className="my-40 mx-20 text-white text-lg">Loading....</p>
  }
  if(data?.result){
    content=<CreateJobFormAdmin type="Update" editJob={data?.result}/>
  }
  return (<>
    {content}
    </>)
}

export default UpdateJobAdmin