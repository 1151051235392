
import {  API_STATIC_URL, imgdefault } from '../../apiConfig';
import { useNavigate } from 'react-router-dom';
import { FaMoneyBillWave } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import { useGetFeaturedJobsQuery } from '../../redux/slice/jobServiceApiSlice';
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactHtmlParser from 'html-react-parser';

//  const Slides = () => {
//   const {data:featuredJobs}=useGetFeaturedJobsQuery("jobsList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})

//   const navigate = useNavigate();


//   const goToJobListing = (item) =>{
//      navigate(`/JobDetails/${item.jobId}`);
// }


//   return (
//       <div className="">
//         <Carousel showThumbs={false}
//         autoPlay
//         showStatus={false}
//         showArrows={false}
//         infiniteLoop={true}
//         >
//           {featuredJobs?.result?.length>0 && featuredJobs?.result?.map(job => (
//               <div key={job.jobId} className="relative md:max-w-sm  w-[85%] p-4 h-[440px] rounded-lg overflow-hidden border-2 border-gray-500 shadow-md bg-white transition">
//                 <div className="flex flex-col gap-2 sm:text-sm">
//                   <p className="text-blue-700 mt-1 md:text-2xl sm:text-base font-semibold">{job.jobTitle}</p>
//                   <div className='w-full flex'>
//                   <img 
//                   src={`${job.imageUrl ? `${API_STATIC_URL}${job.imageUrl}`: `${imgdefault}`}`}
//                   className='h-5 m-2' style={{width:"32px"}} alt="" /> 
//                   <p className="flex items-center text-gray-700 gap-2 md:text-xl sm:text-base font-semibold text-wrap">{job.companyName}</p>
//                   </div>
//                   <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base  "><FaLocationDot/> Location: {job.location}</p>
//                   <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base  "><FaMoneyBillWave/> Salary: {job.salary}</p>
//                   <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base "><FaBriefcase/> Experience: {job.minExperience} - {job.maxExperience} yrs</p>
//                   <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base ">Category: {job.categoryName}</p>
//                   <p className="flex text-gray-700 gap-2 sm:text-sm mt-2 text-left mb-3">{ReactHtmlParser(job.jobDescription)[0]}</p>
//                   <button className="rounded-md font-semibold text-white bg-blue-800 mt-3 text-center w-28 h-8"
//                     onClick={()=>goToJobListing(job)}> View Job </button>
//                   </div>
//               </div>
//           ))}
//         </Carousel>
//       </div>
//   )
// }
 const Slides = () => {
  const {data:featuredJobs}=useGetFeaturedJobsQuery("jobsList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})

  const navigate = useNavigate();


  const goToJobListing = (item) =>{
     navigate(`/JobDetails/${item.jobId}`);
}


  return (
      <div className="carouselwidth">
        <Carousel showThumbs={false}
        autoPlay
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        >
          {featuredJobs?.result?.length>0 && featuredJobs?.result?.map(job => (
              <div key={job.jobId} className="relative md:max-w-sm carouselwidth h-[440px] rounded-lg overflow-hidden border-2 border-gray-500 shadow-md bg-white transition">
                <div className="flex flex-col p-4 gap-2 sm:text-sm">
                  <p className="text-blue-700 mt-1 md:text-2xl sm:text-base font-semibold">{job.jobTitle}</p>
                  <div className='w-full flex'>
                  <img 
                  src={`${job.imageUrl ? `${API_STATIC_URL}${job.imageUrl}`: `${imgdefault}`}`}
                  className='h-5 m-2' style={{width:"32px"}} alt="" /> 
                  <p className="flex items-center text-gray-700 gap-2 md:text-xl sm:text-base font-semibold text-wrap">{job.companyName}</p>
                  </div>
                  <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base  "><FaLocationDot/> Location: {job.location}</p>
                  <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base  "><FaMoneyBillWave/> Salary: {job.salary}</p>
                  <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base "><FaBriefcase/> Experience: {job.minExperience} - {job.maxExperience} yrs</p>
                  <p className="flex items-center text-gray-700 gap-2 sm:text-sm mt-2 text-base ">Category: {job.categoryName}</p>
                  <p className="flex text-gray-700 gap-2 sm:text-sm mt-2 text-left mb-3">{ReactHtmlParser(job.jobDescription)[0]}</p>
                  <button className="rounded-md font-semibold text-white bg-blue-800 mt-3 text-center w-28 h-8"
                    onClick={()=>goToJobListing(job)}> View Job </button>
                  </div>
              </div>
          ))}
        </Carousel>
      </div>
  )
}

export default Slides;

