import React, { useState,useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {IMAGE_ROUTES, RECAPCHASITEKEY,} from "../../apiConfig";
import { useNavigate,useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import Toggle from "../GlobalComp/ToggleButton";
import { Link, createSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuthenticateMutation } from "../../redux/slice/userServiceApiSlice";

const Login = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailMobile, setEmailMobile] = useState("");
  const [authenticate]  =useAuthenticateMutation()
  const [emailMobileEmpty, setEmailMobileEmpty] = useState(false); // Track if email field is empty
  const [passwordEmpty, setPasswordEmpty] = useState(false); 
  const [toggle, setToggle] = useState(false);
  const {uid}=useParams()
  useEffect(()=>{
    if(Number(uid)===2){
      setToggle(true)
    }
  },[uid])
  const handleToggleChange = () => {
    setToggle(pre=>!pre);
    setEmailMobile("")
  };

  const handleVerify = () => {
    setIsVerified(pre=>!pre);
  };


  const loginClicked = async (e) => {
    e.preventDefault();
    if (emailMobile === "" || emailMobile===null || password === "") {
      toast.error("Please Enter your credentials", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
      if (emailMobile === "" || emailMobile===null) setEmailMobileEmpty(true); // Set emailEmpty to true if email is empty
      if (password === "") setPasswordEmpty(true);

      return;
    }
      setEmailMobileEmpty(false); // Reset emailEmpty
      setPasswordEmpty(false); // Reset passwordEmpty

      try {
        let userData;
        let payload = {userName: emailMobile,userPassword: password};
        if (!toggle) {
            payload.userRoleId= 3
          userData = await authenticate({...payload})
        } else {
          payload.userRoleId= 2
          userData = await  authenticate({...payload})
        }
        if (userData?.data?.result) {
          localStorage.setItem("vlinks", JSON.stringify(userData?.data?.result));
          toast.success("Login Successful", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          if (!toggle) {
            navigate({
              pathname: '/JobSearch',
              search: `?${createSearchParams({ catId:userData?.data?.result?.categoryId})}`,
            });
          } else{
            navigate("/RecruiterDash");
          }
        } else {
          toast.error("Login failed. Invalid credentials.", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        toast.error(error);
        setPassword("");
        toast.error("Login failed. Please check your credentials.", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Login</title>
        <link rel="canonical" href="https://vlinkjobs.com/Login" />
      </Helmet>

       <div className="flex md:flex-row sm:flex-col gap-4 px-2 justify-between items-center  bodybg  font-custom-font ">
      <div className="flex  items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        <div className="md:min-h-screen md:w-[450px] w-full flex items-center  justify-center md:py-2 sm:py-4 md:px-4">
          <div className="flex items-center flex-col space-y-5 bg-blue-200 md:px-10 w-full rounded-2xl border border-blue-400 hover:border-blue-900">
            <Link
              className="flex justify-center items-center"
              to="/"
            >
              <img
                src={`${IMAGE_ROUTES.altLogo}`}
                alt="Logo"
                className="max-h-28"
              />
            </Link>
            <div className="flex flex-col items-center gap-5">
              <h2 className="relative text-center text-2xl font-extrabold text-blue-900">
                Login to your account
              </h2>
              <Toggle toggle={toggle} handleToggleChange={handleToggleChange} />
            </div>

            <div className="relative w-11/12 space-y-6">
              <div className="rounded-md shadow-sm space-y-6">
                <div className="flex flex-col gap-1">
                  <label htmlFor="email">{toggle ? "Email Address" : "Mobile Number"}</label>
                  <input
                  id="email"
                  value={emailMobile}
                  type={toggle ? "email" : "tel"}
                  onChange={(e) => {setEmailMobile(e.target.value);setEmailMobileEmpty(false)}}
                  className={`relative block w-full px-3 py-2 border ${
                    emailMobileEmpty
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  placeholder={toggle ? "Enter Valid Email Address" : "Enter Mobile Valid Number"}
                />
                {emailMobileEmpty&&<p className="text-red-600 text-sm">{toggle?"*Enter Valid Email Address":"*Enter Valid Mobile Number"}</p>}
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="passwordInput">Password</label>
                  <div className="flex flex-row">
                    <input
                      id="passwordInput"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => {setPassword(e.target.value);setPasswordEmpty(false)}}
                      className={`relative block w-full px-3 py-2 border ${
                        passwordEmpty
                          ? "border-red-500 animate-shake"
                          : "border-gray-300"
                      } placeholder-gray-500 text-gray-900 rounded-l-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                      placeholder="Enter Your Password"
                    />
                    <button
                      onClick={()=>setShowPassword(p=>!p)}
                      className="flex items-center bg-gray-200 relative w-11 inset-y-0 rounded-r-lg right-0 px-3 py-2 text-gray-500 border border-gray-300 focus:outline-none hover:bg-gray-300 hover:border-gray-500"
                    >
                      <FaEye />
                    </button>
                  </div> 
                  {passwordEmpty&&<p className="text-red-600 text-sm">*Enter Valid Password</p>}
                  </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>
                <div className="text-sm">
                  <Link
                    className="font-medium text-sm text-blue-900 hover:text-black hover:cursor-pointer"
                 to={`/Loginotp/${toggle?2:3}`}
                  >
                     Sign in with OTP
                  </Link>
                </div>
              </div>
                <div className="flex flex-col items-center">
                <ReCAPTCHA
            sitekey={RECAPCHASITEKEY}
            size="normal"
            onChange={handleVerify} 
          /> 
                  <button
                   disabled={!isVerified}
                    onClick={loginClicked}
                    className="bg-yellow-400 hover:bg-yellow-500 w-full text-black font-bold py-2 px-4 mt-2 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Sign in
                  </button>
                </div>
              <div>
                <div className="flex items-center justify-end mb-2 gap-2">
                  <div className="">New here?</div>
                  {/* <Link
                    to={`/Register/${toggle?2:3}`}
                    className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-5 h-10 flex items-center rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Sign Up
                  </Link> */}
                  <button  onClick={()=>navigate(`/Register/${toggle?2:3}`)} size="md" className="font-semibold">Register Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
      </div>
    </>
  );
};

export default Login;
