import { useNavigate, useParams } from "react-router-dom";
import { ADSENSESLOTS, API_STATIC_URL } from "../../apiConfig";
import { useEffect, useState } from "react";
import { useGetRecruiterQuery } from "../../redux/slice/recruiterServiceApiSlice";
import { toast } from "react-toastify";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AdsComponent from "../GlobalComp/AdsComponent";
import { HiOfficeBuilding } from "react-icons/hi";
import { MdDescription } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaBriefcase, FaPhoneAlt, FaUser } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useGetCandidateProfileQuery } from "../../redux/slice/jobServiceApiSlice";


const ViewCandidateDetails = () => {
    const { id } = useParams();
   const { data: getCandidateProfile, } = useGetCandidateProfileQuery(id)
    const [fileUrl,setFileUrl]=useState("")
    const [fileType,setFileType]=useState("")

    useEffect(() => {
        if (getCandidateProfile?.result?.profileAttachment) {
           setFileUrl(`${API_STATIC_URL}${getCandidateProfile?.result?.profileAttachment}`)
           setFileType(getCandidateProfile?.result?.profileAttachment.split('.').pop().toLowerCase())
        } 
    }, [getCandidateProfile?.result]);


 
    const downloadResume = (resumeContent) => {
        const fileUrl = `${API_STATIC_URL}${resumeContent}`;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Resume'; // Specify the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>V Link Jobs - Profile</title>
                <link rel="canonical" href="https://vlinkjobs.com/UserProfile" />
            </Helmet>
            <div className='relative font-custom-font flex md:flex-row sm:flex-col mt-24 mb-4  w-full items-center justify-center 2xl:gap-6  lg:justify-between  z-3 '>
                <div className=' flex items-center sm:hidden lg:block'>
                    <div className=''>
                        <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
                    </div>
                </div>
                {getCandidateProfile?.result && (
                    <div className="flex flex-col text-black p-8 rounded-lg bg-blue-200 shadow-lg md:w-3/5 sm:w-full mx-auto sm:mx-2">
                        <div className='relative flex flex-row w-full items-center justify-center'>
                            {getCandidateProfile?.result.imageUrl ? (
                                <img
                                    src={`${API_STATIC_URL}${getCandidateProfile?.result.imageUrl}`}
                                    alt="Profile Pic"
                                    className="md:w-16 md:h-16 sm:w-10 sm:h-10 rounded-full md:mr-6 sm:mr-3 mb-6"
                                />
                            ) : (
                                <img
                                    src="./assets/nophoto.png"
                                    alt="Default Profile Pic"
                                    className="md:w-16 md:h-16 sm:w-10 sm:h-10 rounded-lg md:mr-4 sm:mr-3 mb-6"
                                />
                            )}
                            <h1 className="md:text-3xl sm:text-nowrap font-bold mb-4 self-center text-center">
                              {getCandidateProfile?.result?.fullName}
                            </h1>
                        </div>
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-10">
                            <div className='flex flex-row bg-blue-400 rounded-lg p-2 gap-4 hover:scale-105 transition'>
                                <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                    <FaUser />
                                </div>
                                <div className=' text-gray-700 font-semibold flex flex-col justify-between'>
                                    <div className=' md:text-lg text-gray-700 font-semibold'  >
                                        Name
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result?.fullName}
                                    </div>
                                </div>
                            </div>


                            <div className='flex flex-row bg-blue-400 rounded-lg p-2 gap-4 hover:scale-105 transition'>
                                <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                    <FaBriefcase />
                                </div>
                                <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                    <div className=' md:text-lg text-gray-700 font-semibold'  >
                                        Experience
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result.experience}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-stretch items-center'>
                                <div className='flex flex-row bg-blue-400 rounded-lg p-2 w-full gap-4 hover:scale-105 transition'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <FaPhoneAlt />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                        <div className=' md:text-lg text-gray-700 font-semibold flex'  >
                                            Mobile
                                        </div>
                                        <div className='text-sm text-black'>
                                            {getCandidateProfile?.result.mobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-stretch items-center'>
                                <div className='flex flex-row bg-blue-400 rounded-lg p-2 gap-4 w-full hover:scale-105 transition text-wrap'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <IoMdMail />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between text-wrap '>
                                        <div className='md:text-lg text-gray-700 font-semibold flex'  >
                                            Email
                                        </div >
                                        <div className='text-black text-wrap text-sm'>
                                            {getCandidateProfile?.result.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info-item flex flex-col justify-center  items-center bg-white rounded-lg shadow-lg hover:scale-105 transition ease-in-out bg-opacity-50">
                                <div className='flex flex-row bg-blue-400 rounded-lg h-full p-4 w-full gap-4 transition'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <MdDescription />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                        <div className=' md:text-lg text-gray-700 font-semibold'  >
                                            Resume
                                        </div>
                                        {getCandidateProfile?.result.profileAttachment ? (
                                            <button size='sm' color="warning"
                                                onClick={() => downloadResume(getCandidateProfile?.result.profileAttachment)}
                                            >
                                              Download Resume
                                            </button>
                                        ) : (
                                            <p className="md:text-sm text-black h-1/2 m-2">Not Uploaded</p>
                                        )}
                                    </div>
                                </div>


                            </div>
                            <div className='flex flex-row bg-blue-400 rounded-lg p-2 gap-4 w-full hover:scale-105 transition'>
                                <div className='flex items-center bg-blue-300 p-4 text-gray-700 rounded-lg'>
                                    <HiOfficeBuilding size="28" />
                                </div>
                                <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                    <div className='md:text-lg text-gray-700 font-semibold'  >
                                        Address
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result?.address}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {getCandidateProfile?.result?.profileAttachment&&<div className="flex sm:flex-col md:flex-row justify-center mt-8 gap-8">
                            {fileType ==="pdf" ? <embed src={fileUrl} type="application/pdf" width="100%" height="700px" />:<DocViewer
                                documents={[{ uri: fileUrl,fileType:fileType, fileName: "Resume" }]}
                                pluginRenderers={DocViewerRenderers}
                                style={{ height: 1000, width: "100%" }}
                            />}
                        </div>}
                    </div>
                )}
                <div className='hidden lg:block'>

                    <div className=''>
                        <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
                    </div>
                </div>

            </div>
        </>
    );
};


export default ViewCandidateDetails