import BrowseJobs from "./BrowseJobs";
import NavSearch from "./NavSearch";
import NavImage from "../GlobalComp/NavImage";
import "tailwindcss/tailwind.css";
import { Helmet } from "react-helmet";

 const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Home</title>
        <link rel="canonical" href="https://vlinkjobs.com" />
      </Helmet>
      <div className="font-custom-font w-full">
        <div className="relative z-0 w-full">
          <NavImage />
        </div>
        <div className="relative z-3">
            <NavSearch />
        </div>
        <div className="relative z-3 ">
          <BrowseJobs />
        </div>
      </div>
    </>
  );
}; 



export default Home;
