import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAdminApproveJobMutation, useGetJobByIdQuery } from '../../../redux/slice/jobServiceApiSlice';
import { API_STATIC_URL, imgdefault } from '../../../apiConfig';
import { MdDelete, MdEdit, MdEmail } from 'react-icons/md';
import { FaBriefcase, FaMoneyBillWave, FaPhoneAlt } from 'react-icons/fa';
import { HiOfficeBuilding } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { useDeleteJobMutation } from '../../../redux/slice/recruiterServiceApiSlice';
import ReactHtmlParser from 'html-react-parser';

const JobDetailsAdmin = () => {
    const {jobId}=useParams()
    const { data:jobDetails,refetch } = useGetJobByIdQuery(jobId,{pollingInterval:6000,refetchOnFocus:true,refetchOnMountOrArgChange:true});
    const navigate = useNavigate()
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [deleteJob] = useDeleteJobMutation();
    const [adminApproveJob] = useAdminApproveJobMutation();

    const EditJob =()=>{
        navigate(`/admindash/updatejobadmin/${jobId}`)
    }

    const confirmDelete = () => { // Set the selected job id
      setConfirmVisible(true); // Show the confirmation dialog
    };
  
    const handleApproveJob =async()=>{
      await adminApproveJob({isApproved:1,jobId})
      await refetch()
      /* navigate(-1) */
    }

    const handleNotApproveJob =async()=>{
      await adminApproveJob({isApproved:0,jobId})
      await refetch()
      /* navigate(-1) */
    }

    const deleteJobHandler = async () => {
      try {
        const response = await deleteJob(jobId);
  
        if (response.data.success === true) {
          // Refresh the listings
          toast.success("Job Deleted Successfully", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          await refetch()
          navigate(-1)
        } else {
          toast.error("Failed to Delete Job", {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting job:", error);
      } finally {
        setConfirmVisible(false); // Hide the confirmation dialog
      }
    };
  return (
    <div >
        <div className='flex justify-between px-2'>
        <p className='text-blue-500 font-bold text-xl'>Job Details</p>
        <div className="flex gap-4 px-4 ">
            <button onClick={EditJob} className='p-2 bg-orange-500 text-white rounded-lg font-normal text-md'>Edit Job</button>
            <button  onClick={(e) => {e.stopPropagation();confirmDelete()}} className='p-2 bg-red-500 text-white rounded-lg font-normal text-md'>Delete Job</button>
        </div>
        </div>
       
        {jobDetails?.result && (
              <div className="flex flex-col md:w-lg md:m-10 p-8 rounded-lg shadow-lg z-10">

                  <div className='flex justify-between mb-6'>
                    <h1 className="md:text-2xl sm:text-xl  font-bold text-nowrap">
                      {jobDetails?.result?.jobTitle}
                    </h1>
                    <h1 className={`md:text-xl ${jobDetails?.result?.isApproved?`text-green-400`:`text-orange-400`} font-bold text-nowrap`}>
                      {jobDetails?.result?.isApproved?"Approved":"Require Approve"}
                    </h1>
                  </div>
                <hr className="border border-blue-300  mb-10" />
                <div className="flex md:flex-row sm:flex-col md:items-center mb-6 justify-between ">
                  <div className="flex  items-center md:w-1/2 mb-6">
                    <img
                      className="md:h-14 md:w-14 sm:w-8 sm:h-8 mr-4"
                      src={`${jobDetails?.result?.imageUrl ? `${API_STATIC_URL}${jobDetails?.result?.imageUrl}`: `${imgdefault}`}`}
                      alt=""
                    />
                    <div>
                      <p className="md:text-lg sm:text-sm font-semibold">
                        {jobDetails?.result?.companyName}
                      </p>
                      <div className="flex items-center sm:text-sm">
                        <HiOfficeBuilding className="mr-2" />
                        <span className="text-gray-700">
                          {jobDetails?.result?.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:w-full md:w-1/2 items-end">
                    <p className="text-sm font-normal ">
                      {jobDetails?.result?.datePosted}
                    </p>
                    <p className="text-sm font-normal">
                      Category: {jobDetails?.result?.categoryName}
                    </p>
                    <p className="text-sm font-normal">
                      SubCategory: {jobDetails?.result?.subcategoryName}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between flex-wrap gap-4 sm:text-sm">
                  <div className="flex items-center">
                    <FaMoneyBillWave className="mr-2" /> Salary:
                    <span className="text-gray-700">
                      {jobDetails?.result?.salary}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <FaBriefcase className="mr-2" /> Experience:
                    <span className="text-gray-700">
                      {jobDetails?.result?.minExperience} to{" "}
                      {jobDetails?.result?.maxExperience} years
                    </span>
                  </div>
                  <div className="flex items-center">
                    <FaPhoneAlt className="mr-2" /> Mobile:
                    <span className="text-gray-700">
                      {jobDetails?.result?.mobile}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <MdEmail className="mr-2" /> Email:
                    <span className="text-gray-700">{jobDetails?.result?.email}</span>
                  </div>
                </div>
                <p className=" flex flex-row items-start text-xl my-4 sm:text-sm">
                  Description:
                </p>
                <div className="font-normal text-sm text-gray-600 mb-4 ">
                  {" "}
                  {ReactHtmlParser(jobDetails?.result?.jobDescription)}
                </div>
                <div className="flex justify-center gap-5 items-end h-full">
                  <button onClick={() => navigate(-1)}  className='bg-gray-800 text-white font-normal w-32 p-2 rounded-md'>Back</button>
                  {jobDetails?.result?.isApproved? <button onClick={handleNotApproveJob} className='bg-orange-800 text-white font-normal w-32 p-2 rounded-md'>Disapprove</button>
                  :<button onClick={handleApproveJob} className='bg-green-800 text-white font-normal w-32 p-2 rounded-md'>Approve Job</button>}
                
                </div>
              </div>
            )}

   {confirmVisible && (
        <div
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 font-custom-font"
        >
          <div className="flex flex-col bg-blue-300 rounded-lg">
            <div className="p-5 items-center flex ">
              <div className="text-2xl ">
                Are you sure you want to delete this job?
              </div>
            </div>
            <div className="flex gap-5 justify-end align-center text-right px-5 py-5 pt-0">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={deleteJobHandler}
              >
                Delete
              </button>
              <button
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setConfirmVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
        </div>
  )
}

export default JobDetailsAdmin
