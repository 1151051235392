
import 'tailwindcss/tailwind.css';

const benefits = [
  "Increased Efficiency: Streamline your business processes with tailor-made software, saving time and resources.",
  "Enhanced Productivity: Optimize workflow and improve collaboration for outstanding results.",
  "Improved Scalability: Easily scale as your business grows, ensuring seamless operations.",
  "Enhanced Security: Prioritize data security with robust measures, keeping sensitive information safe.",
  "User-Friendly Interface: Enjoy intuitive software design for a pleasant user experience.",
  "Customizable Features: Tailor features to suit your specific requirements.",
  "Ongoing Support: Comprehensive support and maintenance services beyond development."
];


const AboutUs = () => {
  return (

    <div className='flex flex-col font-custom-font  items-center mt-20 min-h-[500px]'>
      <div className='flex flex-col w-3/5 my-6 gap-4'>
        <div className='text-3xl font-bold'>ABOUT US</div>
        <p >VILA Campus is an innovative and software development company that is specialized in building custom software applications that are well tailored to fit your business needs. However, our vision is to provide total transparent cooperation and work excellence. The professionals here work dedicatedly and in a structured way by diving into the understanding process of what the client needs. </p>
      </div>
      <div className='flex flex-col w-3/5 gap-2'>
        <h5 className='font-medium'>Our Software Development services offer a wide range of benefits, including:</h5>
        <div>
          {benefits.map(beni => <div key={beni} className='mx-6 my-1'>{beni}</div>)}
        </div>
      </div>
      <div className='flex flex-col w-3/5 mb-10 mt-4 gap-4'>
        <h5 className='font-medium'> Business Excellence</h5>
        <div className='mx-6 my-1'>

          We are one of the fastest-growing software companies. The technology-driven services, high-end security, mobility, customizable applications, scalable solutions, around twenty years of experience, and affordability are what make us unique from others.
        </div>
      </div>
    </div>

  );
}

export default AboutUs;