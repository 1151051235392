import { apiSlice } from "../apiSlice";

export const userServiceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        registerRecruiter:builder.mutation({
            query: (initialData) => ({
                 url: `/user/registerRecruiter`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        registerCandidate:builder.mutation({
            query: (initialData) => ({
                 url: `/user/registerCandidate`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        authenticate:builder.mutation({
            query: (initialData) => ({
                 url: `/user/authenticate`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        authenticateOTP:builder.mutation({
            query: (initialData) => ({
                 url: `/user/authenticateOTP`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }),
        userChangePassword: builder.mutation({
            query: updateUserData => ({
                url: `/user/userChangePassword/${updateUserData.id}`,
                method: 'PUT',
                body: {...updateUserData}
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
    }),
})
export const { 
  useAuthenticateMutation,useAuthenticateOTPMutation,
  useRegisterCandidateMutation,useRegisterRecruiterMutation,useUserChangePasswordMutation
}
=userServiceApiSlice