import { apiSlice } from "../apiSlice";

export const recruiterServiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    recruiterDashboardData: builder.query({
      query: (id) => ({ url: `/recruiter/recruiterDashboardData/${id}` }),
      providesTags:["Recruiter"]
    }),
    getJobCredits: builder.query({
      query: () => ({ url: `/recruiter/getJobCredits` }),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: "Credit", id }];
        } else return [{ type: "Credit", id: "LIST" }];
      },
    }),
    getRecruiter: builder.query({
      query: (id) => ({ url: `/recruiter/getRecruiter/${id}` }),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: "Recruiter", id }];
        } else return [{ type: "Recruiter", id: "LIST" }];
      },
    }),
    updateRecruiterLogo: builder.mutation({
      query: (initialData) => ({ 
        url: `/recruiter/updateRecruiterLogo`,
        method:"POST",
        body:{...initialData}
       }),
      invalidatesTags: [{ type: "Recruiter", id: "LIST" }]
    }),
    saveRecruiter: builder.mutation({
      query: (initialData) => ({ 
        url: `/recruiter/saveRecruiter`,
        method:"POST",
        body:{...initialData}
       }),
      invalidatesTags: [{ type: "Recruiter", id: "LIST" }]
    }),
    verifyRecruiterMobile: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/verifyRecruiterMobile`,
        method: "POST",
        body: { ...initialData },
      }),
    }),
    verifyRecruiterEmail: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/verifyRecruiterEmail`,
        method: "POST",
        body: { ...initialData },
      }),
    }),
    createOrderRazorPay: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/createOrderRazorPay`,
        method: "POST",
        body: { ...initialData },
      }),
    }),
    validateOrderRazorPay: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/validateOrderRazorPay`,
        method: "POST",
        body: { ...initialData },
      }),
    }),
    postJob: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/postJob`,
        method: "POST",
        body: { ...initialData },
      }),
      invalidatesTags:["Recruiter"]
    }),
    updateJob: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/updateJob`,
        method: "POST",
        body: { ...initialData },
      }),
      invalidatesTags:["Job"]
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `/recruiter/deleteJob/${id}`,
        method: "DELETE",
      }),
      invalidatesTags:[{type:"Job",id:"List"}]
    }),
    saveJobCredits: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/saveJobCredits`,
        method: "POST",
        body:{...initialData}
      }),
      invalidatesTags:["Recruiter"]
    }),
    deleteJobCredits: builder.mutation({
      query: (id) => ({
        url: `/recruiter/deleteJobCredits/${id}`,
        method: "DELETE",
      }),
      invalidatesTags:[{type:"Credit",id:"List"}]
    }),
    updateRecruiterCredits: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/updateRecruiterCredits`,
        method: "Post",
        body:{...initialData}
      }),
      invalidatesTags:[{type:"Credit",id:"List"}]
    }),
    getRecruiterTotalJobs: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/getRecruiterTotalJobs`,
        method: "Post",
        body:{...initialData}
      }),
      invalidatesTags:[{type:"Job",id:"List"}]
    }),
    jobApplicants: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/jobApplicants`,
        method: "Post",
        body:{...initialData}
      }),
      invalidatesTags:[{type:"Job",id:"List"}]
    }),
    updateJobApplicationStatus: builder.mutation({
      query: (initialData) => ({
        url: `/recruiter/updateJobApplicationStatus`,
        method: "Post",
        body:{...initialData}
      }),
      invalidatesTags:[{type:"Job",id:"List"}]
    }),
    recruiterJobInfo: builder.query({
      query: (id) => ({url: `/recruiter/recruiterJobInfo/${id}`,}),
      providesTags: (result, error, id) => {
        if (result?.result) {
          return [{ type: "Job", id }];
        } 
      },
    }),
    getAllRecruiters: builder.mutation({
      query: (initialData) => ({ 
        url: `/recruiter/getAllRecruiters`,
        method:"POST",
        body:{...initialData}
      }),
      invalidatesTags:["Recruiter"]
    }),
    accessProfiles: builder.mutation({
      query: (initialData) => ({ 
        url: `/recruiter/accessProfiles`,
        method:"POST",
        body:{...initialData}
      }),
      invalidatesTags:[{type:"Recruiter",id:"List"}]
    }),
    getCategoryProfiles: builder.mutation({
      query: (initialData) => ({
         url: `/recruiter/getCategoryProfiles` ,
         method:"POST",
         body:{...initialData}
        }),
      invalidatesTags:[{ type: 'Candidate', id: 'LIST' }]
    }),
    deleteRecruiter: builder.mutation({
      query: (id) => ({ 
        url: `/recruiter/deleteRecruiter/${id}`,
        method:"DELETE",
      }),
      invalidatesTags:[{type:"Recruiter",id:"List"}]
    }),
  }),
});

export const {
  useRecruiterDashboardDataQuery,useGetJobCreditsQuery,useGetRecruiterQuery,
  useUpdateRecruiterLogoMutation,useVerifyRecruiterEmailMutation,useVerifyRecruiterMobileMutation,
  useSaveRecruiterMutation,usePostJobMutation,useUpdateJobMutation,useDeleteJobMutation,
  useCreateOrderRazorPayMutation,useValidateOrderRazorPayMutation,useGetRecruiterTotalJobsMutation,
  useSaveJobCreditsMutation,useDeleteJobCreditsMutation,useUpdateRecruiterCreditsMutation,
  useJobApplicantsMutation,useUpdateJobApplicationStatusMutation, useRecruiterJobInfoQuery,
  useGetAllRecruitersMutation,useDeleteRecruiterMutation,useAccessProfilesMutation,useGetCategoryProfilesMutation,
} = recruiterServiceApiSlice;
