//completed
import { useEffect, useState } from 'react';
import { PAYMENT_GATEWAY_KEY_ID } from '../../apiConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { useCreateOrderRazorPayMutation, useGetJobCreditsQuery, useGetRecruiterQuery, useUpdateRecruiterCreditsMutation, useValidateOrderRazorPayMutation } from '../../redux/slice/recruiterServiceApiSlice';
import { Link, useNavigate } from 'react-router-dom';

const BuyCredits = () => {
  const [creditsToBuy, setCreditsToBuy] = useState(1); // Default to buying 1 credit
  const [totalCost, setTotalCost] = useState(600); // Default total cost
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: getRecruiter } = useGetRecruiterQuery(currentUser?.refId)
  const [createOrderRazorPay] = useCreateOrderRazorPayMutation()
  const [validateRazorPayOrder] = useValidateOrderRazorPayMutation()
  const [paymentResponse, setPaymentResponse] = useState(null);
  const { data: getJobCredits } = useGetJobCreditsQuery()
  const [updateRecruiterCredits] = useUpdateRecruiterCreditsMutation()
  const navigate = useNavigate()
  useEffect(() => {
    if (getJobCredits?.result.length) {
      const defaultCredit = getJobCredits?.result[0]
      setCreditsToBuy(defaultCredit.creditCount)
      setTotalCost(defaultCredit.costINR)
    }
  }, [getJobCredits])

  const handleCredits = (e) => {
    const selectedCredits = getJobCredits?.result?.filter(credit => credit.id === Number(e.target.value))
    setCreditsToBuy(selectedCredits[0]?.creditCount);
    setTotalCost(selectedCredits[0]?.costINR);
  };

  const handleRazorpayPayment = async () => {
    try {
      const response = await createOrderRazorPay({ amount: totalCost * 100, currency: "INR" })
      if ("data" in response) {
        const order = response.data;
        const options = {
          key: PAYMENT_GATEWAY_KEY_ID,
          amount: order.amount,
          currency: order.currency,
          order_id: order.id,
          name: getRecruiter?.result?.companyName,
          description: 'Purchase Credits',
          handler: async function (response) {
            const body = {
              ...response,
            };
            const validateRes = await validateRazorPayOrder({ ...body });

            setPaymentResponse(validateRes)
            if (validateRes.data.msg === "success") {
              toast.success('Payment Successful', {
                autoClose: 1000,

                style: {
                  width: '300px',
                }
              })
              const payloadFinal = {
                recruiterId: currentUser.refId,
                transactionId: validateRes.data.razorpay_order_id,
                credits: creditsToBuy,
                amount: totalCost
              }
              const response = await updateRecruiterCredits(payloadFinal)

              toast.success(response.data.message);
            } else {
              toast.error('Payment Failed')
            }
          },
          prefill: {
            name: getRecruiter.recruiterName,
            email: getRecruiter.email,
            contact: getRecruiter.mobile,
          },
          notes: {
            address: getRecruiter.address,
          },
          theme: {
            color: '#41C9E2',
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Buy Credits</title>
        <link rel="canonical" href="https://vlinkjobs.com/BuyCredits" />
      </Helmet>
      <div className='bodybg min-h-screen mt-18 flex items-center flex-col font-custom-font'>
        <h2 className='text-3xl font-bold m-10 text-blue-900'>Buy Credits</h2>
        <div className='rounded-lg flex flex-col min-w-[350px] items-center gap-3 bg-blue-100 p-10 text-xl border-blue-200 border-2 shadow-lg font-normal'>
          <label className='font-semibold gap-4 flex items-center'>
            Select Credits:
            <select className='rounded-lg p-2 h-10 transition w-16' defaultValue={creditsToBuy} onChange={handleCredits}>
              {getJobCredits?.result?.map(credit => (
                <option key={credit.id} value={credit.id}>{credit.creditCount}</option>
              ))}
            </select>
          </label>
          <div className='font-semibold text-xl bg-blue-200 rounded-lg p-2 mt-2'>Total Cost: ₹{totalCost}</div>
          <div className='flex justify-between w-full my-4'>
            <Link className='bg-amber-400 rounded-lg p-3 h-10 hover:bg-amber-500 hover:scale-105 transition mt-4 text-md flex items-center' to="/RecruiterDash">Back</Link>
              <button className='bg-green-900 text-white rounded-lg p-3 h-10 hover:bg-green-700 hover:scale-105 transition mt-4 text-md flex items-center' onClick={handleRazorpayPayment}>Buy Credits</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyCredits;
