import { apiSlice } from "../apiSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
      sendLoginMobileOTP:builder.mutation({
            query: (initialData) => ({
                 url: `/notification/sendLoginMobileOTP`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        sendVerifyMobileOTP:builder.mutation({
            query: (initialData) => ({
                 url: `/notification/sendVerifyMobileOTP`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        sendLoginEmailOTP:builder.mutation({
            query: (initialData) => ({
                 url: `/notification/sendLoginEmailOTP`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
        sendVerifyEmailOTP:builder.mutation({
            query: (initialData) => ({
                 url: `/notification/sendVerifyEmailOTP`,
                 method:"POST",
                 body:{...initialData}
                 }),
        }), 
    }),
})
export const { 
  useSendLoginEmailOTPMutation,useSendLoginMobileOTPMutation,
  useSendVerifyEmailOTPMutation,useSendVerifyMobileOTPMutation
}
=notificationApiSlice