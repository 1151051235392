import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_STATIC_URL } from '../../apiConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaBriefcase } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { HiOfficeBuilding } from "react-icons/hi";
import { MdDescription } from "react-icons/md";
import { Helmet } from 'react-helmet';
import AdsComponent from '../GlobalComp/AdsComponent';
import { ADSENSESLOTS } from '../../apiConfig';
import { useGetCandidateProfileQuery, useUpdateCandidateProfileMutation, useUpdateCandidateProfilePicMutation, useUploadNewResumeMutation, useVerifyCandidateEmailMutation, useVerifyCandidateMobileMutation } from '../../redux/slice/jobServiceApiSlice';
import { useSendVerifyEmailOTPMutation, useSendVerifyMobileOTPMutation } from '../../redux/slice/notificationApiSlice';
import { createSearchParams } from 'react-router-dom';

// Profile Picture Upload Popup Component
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => { // Correct assignment of onload event
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => { // Correct assignment of onerror event
            reject(error);
        };
    });
};

const VerifyEmailModal = ({ closeEmailOTPModal, emailId, candidateId, refetch }) => {
    const [verifyCandidateEmail] = useVerifyCandidateEmailMutation()
    const [OTP, setOTP] = useState(null);
    const submitEmailOTP = async () => {
        const payload = {
            candidateId,
            emailId,
            otp: OTP
        }
        try {
            const response = await verifyCandidateEmail(payload)
            if (response.data.success === true) {
                toast.success("Email Verified", {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
                refetch()
                closeEmailOTPModal();
            }
            else {
                toast.error('Invalid OTP and Try Again!', {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
            }
        } catch (error) {
            toast.error(error, {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            });
        }
    }


    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-blue-300 rounded-lg p-8">
                <button className="flex bg-red-500 self-end rounded-lg px-4 py-1 " onClick={closeEmailOTPModal}>X</button>
                <h2 className="text-xl font-bold mb-4">Enter OTP sent to your Email Address</h2>
                <input className='h-10 ' onChange={(e) => setOTP(e.target.value)} />

                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4" onClick={submitEmailOTP}>Submit OTP</button>
            </div>
        </div>
    );
};


const VerifyMobileModal = ({ closeMobileOTPModal, mobile, candidateId, refetch }) => {
    const [OTP, setOTP] = useState(null);
    const [verifyCandidateMobile] = useVerifyCandidateMobileMutation()
    const submitMobileOTP = async () => {
        const payload = {
            candidateId,
            mobile: mobile,
            otp: OTP
        }
        try {
            const response = await verifyCandidateMobile(payload)
            if (response.data.success === true) {
                toast.success("Mobile Number Verified", {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
                refetch()
                closeMobileOTPModal();
            }
            else {
                toast.error('Invalid OTP and Try Again!', {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
            }
        } catch (error) {
            toast.error(error, {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            });
        }
    }


    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-blue-300 rounded-lg p-8">
                <button className="flex bg-red-500 self-end rounded-lg px-4 py-1 " onClick={closeMobileOTPModal}>X</button>
                <h2 className="text-xl font-bold mb-4">Enter OTP sent to your Mobile No.</h2>
                <input className='h-10 ' onChange={(e) => setOTP(e.target.value)} />

                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4" onClick={submitMobileOTP}>Submit OTP</button>
            </div>
        </div>
    );
};

const ProfilePicUploadModal = ({ closeModal, refId, refetch,setEditing }) => {
    const [profilePic, setProfilePic] = useState(null);
    const [preview, setPreview] = useState('');
    const [updateCandidateProfilePic] = useUpdateCandidateProfilePicMutation()
    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        setProfilePic(file);
        const previewBase64 = await convertBase64(file);
        setPreview(previewBase64);

    };
    const handleUpload = async () => {
        if (profilePic) {
            const payloadData = {
                profileId: refId,
                profilePic: preview
            }

            try {
                const response = await updateCandidateProfilePic(payloadData)
                // Handle response
                if (response.data.success === false) {
                    toast.error('Upload Failed', {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    });
                } else {
                    toast.success('Uploaded Successfully', {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    });
                    refetch()
                    closeModal();
                    setEditing(false)
                }

            } catch (error) {
                // Handle error
                console.error('Error uploading profile picture:', error);
            }
        } else {
            toast.error('Please select a file.', {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            })
        }

    };

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-blue-300 rounded-lg p-8">
                <button className="flex bg-red-500 self-end rounded-lg px-4 py-1 " onClick={closeModal}>X</button>
                <h2 className="text-xl font-bold mb-4">Upload Profile Picture</h2>
                <input type="file"  accept="image/*" onChange={handleProfilePicChange} />
                {preview && <img src={preview} alt="Preview" className="my-4 w-32 h-32 object-cover" />}
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4" onClick={handleUpload}>Upload</button>
            </div>
        </div>
    );
};


const ResumeUploadModal = ({ closeModal, refId, refetch, setEditing }) => {
    const [resume, setResume] = useState(null);
    const [preview, setPreview] = useState('');
    const [fileType, setFileType] = useState('');
    const [uploadNewResume] = useUploadNewResumeMutation();
    const handleResumeChange = async (e) => {
        const file = e.target.files[0];
        setResume(file);
        setFileType(file.type);
        const previewBase64 = await convertBase64(file);
        setPreview(previewBase64);
    };

    const handleUpload = async () => {
        if (preview) {
            const payloadData = {
                profileId: refId,
                attachment: preview
            };

            try {
                const response = await uploadNewResume(payloadData);
                if (response.data.success === false) {
                    toast.error('Upload Failed', {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    });
                } else {
                    toast.success('Uploaded Successfully', {
                        autoClose: 1000,
                        style: {
                            width: '300px',
                        }
                    });
                    refetch(); // You might want to handle this based on your server response
                    closeModal();
                    setEditing(false);
                }
            } catch (error) {
                console.error('Error uploading resume:', error);
            }
        } else {
            toast.error('Please select a file.', {
                autoClose: 1000,
                style: {
                    width: '300px',
                }
            });
        }
    };

    return (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="flex flex-col bg-blue-300 rounded-lg p-8">
                <button className="flex bg-red-500 self-end rounded-lg px-4 py-1" onClick={closeModal}>X</button>
                <h2 className="text-xl font-bold mb-4">Upload Resume</h2>
                <input type="file" accept=".pdf,.doc,.docx,.ppt,.pptx" onChange={handleResumeChange} />

                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4" onClick={handleUpload}>Upload</button>
                {/* {preview && <p className="my-4">Resume Preview:</p>}
                {renderPreview()} */}
            </div>
        </div>
    );
};


const UserProfile = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [editing, setEditing] = useState(false); // State to toggle editing mode
    const [showProfilePicModal, setShowProfilePicModal] = useState(false);
    const [showResumeModal, setShowResumeModal] = useState(false);
    const [showEmailOTPModal, setEmailOTPModal] = useState(false);
    const [showMobileOTPModal, setMobileOTPModal] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const { data: getCandidateProfile, refetch } = useGetCandidateProfileQuery(currentUser?.refId)
    const [updateCandidateProfile] = useUpdateCandidateProfileMutation()
    const [sendVerifyMobileOTP] = useSendVerifyMobileOTPMutation()
    const [sendVerifyEmailOTP] = useSendVerifyEmailOTPMutation()
    const [fullName, setFullName] = useState(""); // State to store full name input
    const [email, setEmail] = useState(""); // State to store email input
    const [address, setAddress] = useState(""); // State to store address input
    const [experience, setExperience] = useState(0); // State to store experience input
    const [mobile, setMobile] = useState("");

    useEffect(() => {
        if (getCandidateProfile?.result) {
            setFullName(getCandidateProfile?.result?.fullName); // State to store full name input
            setEmail(getCandidateProfile?.result?.email); // State to store email input
            setAddress(getCandidateProfile?.result?.address); // State to store address input
            setExperience(getCandidateProfile?.result?.experience); // State to store experience input
            setMobile(getCandidateProfile?.result?.mobile);
        }
    }, [getCandidateProfile?.result])

    const emailModalOpen = () => {
        toast.success(`OTP sent to ${getCandidateProfile?.result?.email}`, {
            autoClose: 1000,
            style: {
                width: '300px',
            }
        })
        sendVerifyEmailOTP({ emailId: getCandidateProfile?.result?.email })
        setEmailOTPModal(true);
    }
    const mobileModalOpen = async () => {
        toast.success(`OTP sent to ${getCandidateProfile?.result?.mobile}`, {
            autoClose: 1000,
            style: {
                width: '300px',
            }
        })
        await sendVerifyMobileOTP({ mobile: getCandidateProfile?.result?.mobile })
        setMobileOTPModal(true);
    }


    const viewResume = (resumeContent) => {
        const newTab = window.open(`${API_STATIC_URL}${resumeContent}`, '_blank');
        if (newTab) {
            newTab.focus();
        } else {
            alert('Please allow popups for this site to view resumes.');
        }
    };

   /*  const viewResume = (resumeContent) => {
        const fileExtension = resumeContent.split('.').pop().toLowerCase();
        const supportedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'ppt', 'pptx'];
    
        if (supportedExtensions.includes(fileExtension)) {
            if (['doc', 'docx', 'ppt', 'pptx'].includes(fileExtension)) {
                const encodedUrl = encodeURIComponent(`${API_STATIC_URL}${resumeContent}`);
                const newTab = window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`, '_blank');
                if (newTab) {
                    newTab.focus();
                } else {
                    alert('Please allow popups for this site to view resumes.');
                }
            } else {
                const newTab = window.open(`${API_STATIC_URL}${resumeContent}`, '_blank');
                if (newTab) {
                    newTab.focus();
                } else {
                    alert('Please allow popups for this site to view resumes.');
                }
            }
        } else {
            alert('Unsupported file format');
        }
    };
     */

    const handleLogout = () => {
        localStorage.removeItem("vlinks");
        navigate('/login');
    };



    const submitProfile = async () => {
        try {
            const requestData = {
                id: currentUser?.refId,
                fullName,
                email,
                address,
                experience,
                mobile: parseInt(mobile)
                // Note: Resume field is not included here as it is handled separately
            };

            const response = await updateCandidateProfile(requestData)
            if (response.data.success === false) {
                toast.error('Error is Updating Info', {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
            } else {
                toast.success('Profile Updated successfully', {
                    autoClose: 1000,
                    style: {
                        width: '300px',
                    }
                });
                refetch()
                setEditing(false);
            }
        } catch (error) {
            setError('Error updating profile');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>V Link Jobs - Profile</title>
                <link rel="canonical" href="https://vlinkjobs.com/UserProfile" />
            </Helmet>
            <div className='relative font-custom-font flex md:flex-row sm:flex-col mt-24 mb-4  w-full items-center justify-center 2xl:gap-6  lg:justify-between  z-3 '>
                <div className=' flex items-center sm:hidden lg:block'>
                    <div className='w-[350px]'>
                        <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
                    </div>
                </div>
                {!editing && getCandidateProfile?.result && (
                    <div className="flex flex-col text-black p-4 rounded-lg border-2 shadow-lg w-full mx-auto sm:mx-2">
                        <div className='relative flex flex-row w-full items-center justify-center'>
                            {getCandidateProfile?.result.imageUrl ? (
                                <img
                                    src={`${API_STATIC_URL}${getCandidateProfile?.result.imageUrl}`}
                                    alt=""
                                    className="md:w-16 md:h-16 sm:w-8 sm:h-8 md:mr-6 sm:mr-3 mb-4 rounded-md"
                                />
                            ) : (
                                <img
                                    src="./assets/nophoto.png"
                                    alt=""
                                    className="md:w-20 md:h-20 sm:w-10 sm:h-10 md:mr-6 sm:mr-3 mb-6"
                                />
                            )}
                            <span className="md:text-2xl sm:text-nowrap font-bold mb-4 self-center text-center">
                              Welcome, {getCandidateProfile?.result.fullName}!
                            </span>
                        </div>
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-10">
                            <div className='flex flex-row bg-blue-200 rounded-lg p-2 gap-4'>
                                <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                    <FaUser />
                                </div>
                                <div className=' text-gray-700 font-semibold flex flex-col justify-between'>
                                    <div className=' md:text-lg text-gray-700 font-semibold'  >
                                        Name
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result.fullName}
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row bg-blue-200 rounded-lg p-2 gap-4'>
                                <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                    <FaBriefcase />
                                </div>
                                <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                    <div className=' md:text-lg text-gray-700 font-semibold'  >
                                        Experience
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result.experience}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-stretch items-center'>
                                <div className='flex flex-row bg-blue-200 rounded-lg p-2 w-full gap-4'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <FaPhoneAlt />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                        <div className=' md:text-lg text-gray-700 font-semibold flex'  >
                                            Mobile
                                            {getCandidateProfile?.result.isMobileVerify === 0 && (
                                                <button className='bg-blue-500 hover:bg-blue-600 text-white text-sm ml-7 px-2 rounded-lg shadow-lg transition ease-in-out hover:scale-105' onClick={mobileModalOpen} >Verify Mobile</button>
                                            )}
                                        </div>
                                        <div className='text-sm text-black'>
                                            {getCandidateProfile?.result.mobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-stretch items-center'>
                                <div className='flex flex-row bg-blue-200 rounded-lg p-2 gap-4 w-full text-wrap'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <IoMdMail />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between text-wrap '>
                                        <div className='md:text-lg text-gray-700 font-semibold flex'  >
                                            Email
                                            {getCandidateProfile?.result.isEmailVerify === 0 && (
                                                <button className='bg-blue-500 hover:bg-blue-600 text-white text-sm ml-7 px-2 rounded-lg shadow-lg ' onClick={emailModalOpen}>Verify Email</button>
                                            )}
                                        </div >
                                        <div className='text-black text-wrap text-sm'>
                                            {getCandidateProfile?.result.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="info-item flex flex-col justify-center items-center bg-white rounded-lg shadow-lg">
                                <div className='flex flex-row bg-blue-200 rounded-lg h-full p-4 w-full gap-4 transition'>
                                    <div className=' flex items-center text-gray-700 md:text-3xl jusitfy-center bg-blue-300 p-4 rounded-lg'>
                                        <MdDescription />
                                    </div>
                                    <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                        <div className=' md:text-lg text-gray-700 font-semibold'  >
                                            Resume
                                        </div>
                                            {getCandidateProfile?.result.profileAttachment ? (
                                                <button size='sm' className='rounded-md text-white font-thin bg-amber-800 py-2 w-28'
                                                    onClick={() => viewResume(getCandidateProfile?.result.profileAttachment)}
                                                >
                                                    View Resume
                                                </button>
                                            ) : (
                                                <p className="md:text-sm text-black h-1/2 m-2">Not Uploaded</p>
                                            )}
                                    </div>
                                </div>


                            </div>
                            <div className='flex flex-row bg-blue-200 rounded-lg p-2 gap-4 w-full'>
                                <div className='flex items-center bg-blue-300 p-4 text-gray-700 rounded-lg'>
                                    <HiOfficeBuilding size="28"/>
                                </div>
                                <div className=' text-gray-700 font-medium flex flex-col justify-between'>
                                    <div className='md:text-lg text-gray-700 font-semibold'  >
                                        Address
                                    </div>
                                    <div className='text-sm text-black'>
                                        {getCandidateProfile?.result?.address}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex sm:flex-col md:flex-row justify-center mt-8 gap-8">
                            <button color='neutral' onClick={() => setEditing(true)} className='rounded-md font-semibold text-white bg-blue-800 mt-8 px-6 py-2'>Edit Profile</button>
                            <button color='warning' onClick={() => navigate("/changepassword")} className='rounded-md font-semibold text-white bg-red-800 mt-8 px-6 py-2'>Change Password</button>
                        </div>
                    </div>
                )}
                {editing && (
                    <div className="text-center flex flex-col bg-blue-200 gap-2 md:w-2/5 sm:w-4/5 sm:mx-2 p-8  rounded-lg shadow-lg">
                        <h1 className='md:text-2xl sm:text-xl font-bold mb-4 text-blue-800'>Update Your Profile</h1>
                        <div>
                            <h3 className='flex w-full mx-2 text-blue-700  font-medium'>Name :</h3>
                            <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Full Name" className="relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black" />
                        </div>
                        <div>
                            <h3 className='flex w-full mx-2 text-blue-700  mt-2 font-medium'>Email :</h3>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black" />
                        </div>
                        <div>
                            <h3 className='flex w-full mx-2 text-blue-700   mt-2 font-medium'>Mobile :</h3>
                            <input type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile" className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black" />
                        </div>
                        <div>
                            <h3 className='flex w-full mx-2 text-blue-700  mt-2 font-medium'>Address :</h3>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black" />
                        </div>
                        <div>
                            <h3 className='flex w-full mx-2 text-blue-700  mt-2 font-medium'>Experience :</h3>
                            <input type="number" value={experience} onChange={(e) => setExperience(e.target.value)} placeholder="Experience" className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black" />
                        </div>
                        <div className="flex md:flex-row md:gap-4 sm:flex-col sm:gap-2 mt-2 justify-between">
                           <button size='md' onClick={() => setEditing(false)} className='rounded-md font-semibold text-white bg-gray-800 mt-8 px-6 py-2'>Back</button>
                            <button size='md' onClick={() => setShowProfilePicModal(true)} className='rounded-md font-semibold text-white bg-blue-800 mt-8 px-6 py-2'>Upload Profile Picture</button>
                            <button size='md' onClick={() => setShowResumeModal(true)} className='rounded-md font-semibold text-white bg-amber-800 mt-8 px-6 py-2'>Upload Resume</button>
                            <button size='md' onClick={submitProfile} className='rounded-md font-semibold text-white bg-green-800 mt-8 px-6 py-2'>Save</button>
                        </div>
                    </div>

                )}
                <div className='hidden lg:block'>
                    <div className='w-[350px]'>
                        <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
                    </div>
                </div>

            </div>
            <div className='relative z-3 bg-white'>
                {showProfilePicModal && (
                    <ProfilePicUploadModal
                        closeModal={() => setShowProfilePicModal(false)}
                        refId={currentUser?.refId}
                        refetch={refetch}
                        setEditing={setEditing}
                    />
                )}
                {showResumeModal && (
                    <ResumeUploadModal
                        closeModal={() => setShowResumeModal(false)}
                        refId={currentUser?.refId}
                        refetch={refetch}
                        setEditing={setEditing}
                    />
                )}
                {showEmailOTPModal && (
                    <VerifyEmailModal
                        closeEmailOTPModal={() => setEmailOTPModal(false)}
                        emailId={getCandidateProfile?.result?.email}
                        candidateId={currentUser.refId}
                        refetch={refetch}
                    />
                )}
                {showMobileOTPModal && (
                    <VerifyMobileModal
                        closeMobileOTPModal={() => setMobileOTPModal(false)}
                        mobile={getCandidateProfile?.result?.mobile}
                        candidateId={currentUser.refId}
                        refetch={refetch}
                    />
                )}
            </div>
        </>
    );
};

export default UserProfile;


