import Home from './Component/Home/Home';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Component/Login/Login';
import Register from './Component/Register/Register';
import JobSearch from './Component/JobSearch/JobSearch';
import RecruiterDash from './Component/RecruiterDash/RecruiterDash';
import JobDetails from './Component/JobDetails/JobDetails';
import UserProfile from './Component/UserProfile/UserProfile';
import RecruiterProfile from './Component/RecruiterProfile/RecruiterProfile';
import ListingDashboard from './Component/ListingDashboard/ListingDashboard';
import ListingDetails from './Component/ListingDetails/ListingDetails';
import CandidateApplications from './Component/CandidateApplications/CandidateApplications';
import BuyCredits from './Component/BuyCredits/BuyCredits';
import TermsAndConditions from './Component/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import AdminLogin from './Admin/AdminLogin/AdminLogin';
import ChangePassword from './Component/ChangePassword/ChangePassword';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import LoginOTP from './Component/Login/LoginOTP';
import RootLayout from './Component/GlobalComp/RootLayout';
import UpdateJob from './Component/updateJob/UpdateJob';
import CreateNewJob from './Component/CreateJob/CreateNewjob';
import RequireAuthJoobSeeker, { RequireAuthRecruiter,RequireAuthAdmin, RootRouteComponent } from './Component/RequireAuth/RequireAuth';
import Layout, { SubLayout } from './Admin/Layout/Layout';
import JobsForApproval from './Admin/Components/JobsForApproval';
import ApprovedJobs from './Admin/Components/ApprovedJobs';
import TotalRecruiters from './Admin/Components/TotalRecruiters';
import AdminViewProfiles from './Admin/Components/AdminViewJobProfiles/AdminViewProfiles';
import AdminViewProfileDetails from './Admin/Components/AdminViewJobProfiles/AdminViewProfileDetails';
import JobDetailsAdmin from './Admin/Components/Jobs/JobDetailsAdmin';
import UpdateJobAdmin from './Admin/Components/Jobs/UpdateJobAdmin';
import ViewProfiles from './Component/ViewJobProfiles/ViewProfiles';
import AboutUs from './Component/AboutUs/AboutUs';
import ViewProfileDetails from './Component/ViewJobProfiles/ViewProfileDetails';
import ViewCandidateDetails from './Component/ListingDetails/ViewCandidateDetails';




const Rout =  createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route element={<RootRouteComponent/>}>
        <Route path="/Login/:uid" element={<Login />} />
        <Route path="/Loginotp/:uid" element={<LoginOTP />} />
        <Route path="/Register/:uid" element={<Register />} />
        <Route path="/AdminLogin" element={<AdminLogin/>}/> 
        <Route path="/ChangePassword" element={<ChangePassword/>}/>
        <Route element={<RootLayout/>}>
        <Route path="/" element={<Home />} />
        <Route path="/JobSearch" element={<JobSearch />}/>
        <Route path="/JobDetails/:jobId" element={<JobDetails />}/>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/> 
        <Route path="/TermsAndConditions" element={<TermsAndConditions/>}/> 
        <Route path="/aboutus" element={<AboutUs/>}/> 
        <Route element={<RequireAuthJoobSeeker/>}>
        <Route path="/UserProfile" element={<UserProfile />}/> {/* need to work on resume word/ppt */}
        <Route path="/CandidateApplications" element={<CandidateApplications />}/> 
        </Route>
        <Route element={<RequireAuthRecruiter/>}>
        <Route path="/RecruiterDash" element={<RecruiterDash />}/>
        <Route path="/RecruiterProfile" element={<RecruiterProfile />}/> 
        <Route path="/createjob" element={<CreateNewJob />}/> 
        <Route path="/updatejob/:id" element={<UpdateJob />}/> 
        <Route path="/ListingDashboard" element={<ListingDashboard />}/> 
        <Route path="/ListingDetails/:id" element={<ListingDetails />}/> 
        <Route path="/BuyCredits" element={<BuyCredits />}/> 
        <Route path="/viewprofiles" element={<ViewProfiles />}/> 
        <Route path="/viewprofiledetails/:id" element={<ViewProfileDetails />}/> 
        <Route path="/viewcandidatedetails/:id" element={<ViewCandidateDetails />}/> 
        </Route>
        </Route>
        <Route element={<RequireAuthAdmin/>}>
        <Route path="admindash" element={<Layout/>}> 
          <Route path='/admindash' element={<SubLayout/>}> 
          <Route path='requiredapprovaljobs' element={<JobsForApproval/>}/> 
          <Route path='approvedjobs' element={<ApprovedJobs/>}/> 
          <Route path='totalrecruiters' element={<TotalRecruiters/>}/>
          <Route path='adminviewprofiles' element={<AdminViewProfiles/>}/>
          <Route path='adminviewprofiledetails/:id' element={<AdminViewProfileDetails/>}/>
          <Route path='jobdetailsadmin/:jobId' element={<JobDetailsAdmin/>}/> 
          <Route path="updatejobadmin/:id" element={<UpdateJobAdmin />}/>         </Route>
        </Route>
        </Route>
        </Route>
      </Route>
  ))


const App = () => {
  return <RouterProvider router={Rout} />;
}; 

export default App;