//completed
import  { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { ADSENSESLOTS } from "../../../apiConfig";
import { useGetJobCategoriesQuery, useGetJobSubcategoriesQuery } from "../../../redux/slice/jobServiceApiSlice";
import {  usePostJobMutation, useUpdateJobMutation } from "../../../redux/slice/recruiterServiceApiSlice";
import AdsComponent from "../../../Component/GlobalComp/AdsComponent";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }], [{'size': []}],[{'color': []}],[{'background': []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
}

const formats = [
  'header','font','size','color','background',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]
const CreateJobFormAdmin = ({type,editJob}) => {
  const [jobTitle, setJobTitle] = useState(editJob?.jobTitle??"");
  const [jobDescription, setJobDescription] = useState(editJob?.jobDescription??"");
  const [categoryId, setCategoryId] = useState(editJob?.jobCategoryId??1);
  const [subcategoryId, setSubcategoryId] = useState(editJob?.jobSubCategoryId??"");
  const [minExperience, setMinExperience] = useState(editJob?.minExperience??"");
  const [maxExperience, setMaxExperience] = useState(editJob?.maxExperience??"");
  const [salary, setSalary] = useState(editJob?.salary??"");
  const [mobile, setMobile] = useState(editJob?.mobile??"");
  const [email, setEmail] = useState(editJob?.email??"");
  const [location, setLocation] = useState(editJob?.location??"");
  const [isActive, setIsActive] = useState(editJob?.isActive ?? 1);
  const [isDisplayEmail, setIsDisplayEmail] = useState(editJob?.isDisplayEmail ?? 0);
  const [isDisplayMobile, setIsDisplayMobile] = useState(editJob?.isDisplayMobile ?? 0);
  const [submitted, setSubmitted] = useState(false); // Track if form is submitted
  const navigate = useNavigate();
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {pollingInterval: 60000,refetchOnFocus: true,refetchOnMountOrArgChange: true,});
  const { data: subCategories } = useGetJobSubcategoriesQuery(categoryId);
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));/* 
  const { data: getRecruiter } = useGetRecruiterQuery(currentUser?.refId); */
  const [postJob] = usePostJobMutation();
  const [updateJob]=useUpdateJobMutation()
  
/*   useEffect(() => {
    if (getRecruiter?.result) {
      setMobile(getRecruiter?.result?.mobile);
      setEmail(getRecruiter?.result?.email);
    }
  }, [getRecruiter]); */

 
  const handleSubmit = async () => {
    // Set submitted to true
    setSubmitted(true);

    // Check if any required field is empty
    if (
      jobTitle === "" ||
      jobDescription === "" ||
      categoryId === "" ||
      subcategoryId === "" ||
      minExperience === "" ||
      maxExperience === "" ||
      location === ""
    ) {
      toast.error("Please Fill all fields", {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    } else {
      const payload = {
        recruiterId: editJob.recruiterId,
        jobTitle,
        jobDescription: jobDescription,
        jobCategoryId: categoryId,
        jobSubCategoryId: subcategoryId,
        minExperience,
        maxExperience,
        salary,
        mobile,
        email,
        location,
        isActive,
        isDisplayEmail,
        isDisplayMobile
      };
      try {
        let response
        if(type==="Create"){
          payload.isApproved= 0;
          response = await postJob(payload)
        }else{
          payload.isApproved = editJob.isApproved;
          payload.jobId = editJob.jobId;
          response = await updateJob(payload);
        }
        if (response?.data?.success === true) {
          toast.success(response.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
          navigate(`/admindash/jobdetailsadmin/${editJob.jobId}`);
        } else {
          toast.error(response.data.message, {
            autoClose: 1000,
            style: {
              width: "300px",
            },
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Post a New Job</title>
        <link rel="canonical" href="https://vlinkjobs.com/createjob" />
      </Helmet>
      <div className=" font-custom-font   overflow-auto">
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center md:my-10 z-3 w-full ">
          {/* <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div> */}
          <div className="flex flex-col md:w-full border-2 p-4 rounded-lg shadow-lg gap-2 ">
            <h2 className="text-2xl font-bold text-blue-900 mb-2">
              {type==="Create"?"Post a New Job":"Update Job"}
            </h2>
            <div className="flex flex-wrap ">
              {/* Category */}
              <div className="w-full md:w-1/2 px-3 mt-2 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="category_id"
                >
                  Category
                </label>
                <select
                  className={`relative block w-full px-3 py-2 border ${
                    submitted && categoryId.value === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="category_id"
                  name="category_id"
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  <option className="text-black" value="">
                    Select category
                  </option>
                  {categories?.result?.map((category) => (
                    <option className="" key={category.id} value={category.id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
              </div>
              {/* Subcategory */}
              {subCategories?.result?.length > 0 && (
                <div className="w-full md:w-1/2 mt-2 px-3">
                  <label
                    className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                    htmlFor="subcategory_id"
                  >
                    Subcategory
                  </label>
                  <select
                    className={`relative block w-full px-3 py-2 border ${
                      submitted && subcategoryId.value === ""
                        ? "border-red-500 animate-shake"
                        : "border-gray-300"
                    } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                    id="subcategory_id"
                    name="subcategory_id"
                    value={subcategoryId}
                    onChange={(e) => setSubcategoryId(e.target.value)}
                  >
                    <option className="text-black" value="">
                      Select subcategory
                    </option>
                    {subCategories?.result?.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.subcategoryName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="w-full px-3 mt-3">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="job_title"
                >
                  Job Title
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${
                    submitted && jobTitle === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="job_title"
                  name="job_title"
                  type="text"
                  value={jobTitle}
                  placeholder="Software Engineer"
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap mb-7">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="jobDescription"
                >
                  Job Description
                </label>
                <ReactQuill theme="snow" value={jobDescription} onChange={setJobDescription}
          modules={modules} formats={formats} className='h-40 max-lg:mb-16 mb-8 bg-white'  />
              </div>
            </div>

            <div className="flex flex-wrap ">
              {/* Min Experience */}
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="min_experience"
                >
                  Minimum Experience
                </label>
                <input
                  className={`relative block w-12 px-3 py-2 border ${
                    submitted && minExperience === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="min_experience"
                  name="min_experience"
                  type="text"
                  value={minExperience}
                  placeholder="Min" maxLength={2}
                  onChange={(e) => setMinExperience(e.target.value)}
                />
              </div>
              {/* Max Experience */}
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="max_experience"
                >
                  Maximum Experience
                </label>
                <input
                  className={`relative block w-12 px-3 py-2 border ${
                    submitted && maxExperience === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="max_experience"
                  name="max_experience"
                  type="text"
                  value={maxExperience}
                  placeholder="Max" maxLength={2}
                  onChange={(e) => setMaxExperience(e.target.value)}
                />
              </div>
            </div>
            {/* Add salary, mobile, email, and location input fields */}
            <div className="flex flex-wrap ">
              {/* Email */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  disabled
                  className={`relative block bg-gray-200 w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {/* Mobile */}
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="mobile"
                >
                  Mobile
                </label>
                <input
                  disabled
                  className={`relative block bg-gray-200 w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="mobile"
                  name="mobile"
                  type="text"
                  placeholder="Mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap ">
              {/* Salary */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="salary"
                >
                  Salary
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${
                    submitted && salary === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="salary"
                  name="salary"
                  type="text"
                  value={salary}
                  placeholder="Salary"
                  onChange={(e) => setSalary(e.target.value)}
                />
              </div>
              {/* Location */}
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold mb-2"
                  htmlFor="location"
                >
                  Job Location
                </label>
                <input
                  className={`relative block w-full px-3 py-2 border ${
                    submitted && location === ""
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm hover:border-black`}
                  id="location"
                  name="location"
                  type="text"
                  value={location}
                  placeholder="Location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap my-2">
              {/* isJobactive */}
              <div className="w-full flex flex-row items-center gap-1 md:w-1/3 px-3 mb-6 md:mb-0">

                <input type="checkbox" 
                 checked={!!isActive} // Convert isActive to boolean
                 onChange={e => setIsActive(e.target.checked ? 1 : 0)}
                  inputProps={{ 'aria-label': 'controlled' }} />
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold"
                  htmlFor="salary"
                >
                  Is Job Active 
                </label>
              </div>
              {/* isemaildispaly */}
              <div className="w-full flex flex-row items-center gap-1 md:w-1/3 px-3 mb-6 md:mb-0">

                <input type="checkbox" 
                checked={!!isDisplayEmail} // Convert isActive to boolean
                onChange={e => setIsDisplayEmail(e.target.checked ? 1 : 0)}
                  inputProps={{ 'aria-label': 'controlled' }} />
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold"
                  htmlFor="salary"
                >
                  Is Display Email
                </label>
              </div>
              {/* isJobactive */}
              <div className="w-full flex flex-row items-center gap-1 md:w-1/3 px-3 mb-6 md:mb-0">

                <input type="checkbox" 
                checked={!!isDisplayMobile} // Convert isActive to boolean
                onChange={e => setIsDisplayMobile(e.target.checked ? 1 : 0)}
                  inputProps={{ 'aria-label': 'controlled' }} />
                <label
                  className="block uppercase tracking-wide text-blue-800 text-xs font-bold"
                  htmlFor="salary"
                >
                  Is Display Mobile
                </label>
              </div>
            </div>
            <div className="flex flex-wrap my-3">
              <div className="flex  w-full gap-10 justify-center">
                <button
                   className='bg-gray-800 text-white font-normal w-32 p-2 rounded-md'
                   onClick={() => navigate(-1)}>
                  Back
                </button>
                <button
                  className="bg-green-800 text-white font-normal w-32 p-2 rounded-md"
                  onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="my-4 flex items-center sm:hidden md:block ">
            <div className="">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateJobFormAdmin;
