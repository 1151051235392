//completed
import { useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import { API_STATIC_URL, imgdefault } from "../../apiConfig";
import { LiaCoinsSolid } from "react-icons/lia";
import { FaBriefcase } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { HiOfficeBuilding } from "react-icons/hi";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { useRecruiterDashboardDataQuery } from "../../redux/slice/recruiterServiceApiSlice";

const RecruiterDash = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const { data: dashboardData } = useRecruiterDashboardDataQuery(currentUser?.refId,{refetchOnFocus:true,refetchOnMountOrArgChange:true,pollingInterval:6000});

  const goToBuyCredits = () => {
    navigate("/BuyCredits");
  };

  return (
    <div className="App font-custom-font">
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Dashboard</title>
        <link rel="canonical" href="https://vlinkjobs.com/RecruiterDash" />
      </Helmet>

      <div className="App font-custom-font relative mt-20 bodybg overflow-auto">
        <div className="flex sm:flex-col md:flex-row items-center gap-4 w-full py-1">
          <div className="flex items-center sm:hidden md:block ">
            <div className="w-[300px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          {dashboardData?.result ? (
            <div className="flex flex-col items-center justify-center w-full gap-6 mb-10 text-white">
              <div className="flex items-center justify-center gap-3 text-center">
                 <img
                    className="h-14 w-14"
                    src={`${dashboardData?.result?.recruiter?.imageUrl ? `${API_STATIC_URL}${dashboardData?.result?.recruiter?.imageUrl}`: `${imgdefault}`}`}
                    alt=""
                  />
                <h1 className="md:text-3xl sm:text-3xl text-black font-bold">
                  {dashboardData?.result?.recruiter?.companyName}
                </h1>
              </div>
              <div className="flex flex-col gap-3 w-full">
                <div className="flex flex-col gap-2 border-2 border-gray-200 text-black md:px-3 py-3 sm:px-3 rounded-lg transition shadow-lg">
                  <div className="flex flex-col gap-2">
                    <div className="flex md:flex-row sm:flex-col gap-2">
                      <div className="flex flex-row bg-blue-300 w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                          <FaUser />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Name</div>
                          <div>
                            {dashboardData?.result?.recruiter?.recruiterName}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row bg-blue-300 w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                          <FaPhoneAlt />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Mobile</div>
                          <div>{dashboardData?.result?.recruiter?.mobile}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bg-blue-300 w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                          <IoMdMail />
                        </div>
                        <div className=" flex flex-col text-base justify-between ">
                          <div className="font-semibold">Email</div>
                          <div>{dashboardData?.result?.recruiter?.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row bg-blue-300 w-full rounded-lg p-2 gap-4 hover:scale-105 transition">
                    <div className=" flex items-center text-xl jusitfy-center bg-gray-300 p-4 rounded-lg">
                      <HiOfficeBuilding />
                    </div>
                    <div className=" flex flex-col text-base justify-between ">
                      <div className="font-semibold">Address</div>
                      <div>{dashboardData?.result?.recruiter?.address}</div>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row sm:flex-col gap-3 h-full justify-center">
                  <div className="flex flex-col md:w-1/2 sm:w-full h-full  ">
                    <div className="flex bg-blue-400 text-white text-lg font-semibold px-10 py-2 rounded-t-lg">
                      Credits
                    </div>
                    <div className="flex flex-col gap-3 sm:py-3 h-full text-black text-base font-semibold px-4 rounded-b-lg shadow-lg">
                      <div className="flex flex-row  bg-blue-200 rounded-lg p-2 w-full gap-4 hover:scale-105 transition ">
                        <div className=" flex items-center jusitfy-center text-xl bg-orange-300 py-2 px-3 rounded-lg">
                          <LiaCoinsSolid />
                        </div>
                        <div>
                          <div>Credits Used</div>
                          <div className="font-bold">
                            {dashboardData?.result?.recruiter?.creditsUsed}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row bg-blue-200 rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center text-xl  py-2 px-3 jusitfy-center bg-green-300 rounded-lg">
                          <LiaCoinsSolid />
                        </div>
                        <div>
                          <div>Credits Available</div>
                          <div className="font-bold">
                            {dashboardData?.result?.recruiter?.creditsAvailable}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 mb-4 pl-4">
                      <button onClick={goToBuyCredits} size="md" className="bg-green-800 text-white rounded-full w-32 font-normal h-10" >Buy Credits</button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:w-1/2  sm:w-full ">
                    <div className="flex bg-blue-400 text-white text-lg font-semibold px-10 py-2 rounded-t-lg ">
                      Jobs
                    </div>
                    <div className="flex flex-col gap-3 pt-3 pb-3 text-black text-base font-semibold px-4 rounded-b-lg shadow-lg">
                      <div className="flex flex-row bg-blue-200 rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center bg-orange-300 p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Total Jobs</div>
                          <div className="font-bold">{dashboardData?.result?.totalJobs}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bg-blue-200 rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center bg-green-300 p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Active Jobs</div>
                          <div className="font-bold">{dashboardData?.result?.activeJobs}</div>
                        </div>
                      </div>
                      <div className="flex flex-row bg-blue-200 rounded-lg p-2 gap-4 hover:scale-105 transition">
                        <div className=" flex items-center jusitfy-center bg-gray-300 p-4 rounded-lg">
                          <FaBriefcase />
                        </div>
                        <div>
                          <div>Inactive Jobs</div>
                          <div className="font-bold">{dashboardData?.result?.inactiveJobs}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-5 text-nowrap">
                <button onClick={()=>navigate("/createjob")} className="bg-blue-800 text-white font-semibold p-2 rounded-md">Post a New Job</button>
                <button onClick={()=>navigate("/ListingDashboard")} className="bg-green-800 text-white font-semibold p-2 rounded-md">View Posted Jobs</button>
                { dashboardData?.result?.recruiter?.accessProfiles ? <button onClick={()=>navigate("/viewprofiles")} className="bg-amber-800 text-white font-semibold p-2 rounded-md">View All Candidates</button>:""}
               {/*  <Link
                  to="/createjob"
                  className="bg-yellow-400 hover:bg-yellow-500 h-10 flex items-center text-black font-bold py-3 px-5 rounded-lg transition ease-in-out transform hover:scale-105"
                >
                  Post a New Job
                </Link>
                <Link
                  to="/ListingDashboard"
                  className="bg-yellow-400 hover:bg-yellow-500 h-10 flex items-center text-black font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-105"
                >
                  View Posted Jobs
                </Link>
               { dashboardData?.result?.recruiter?.accessProfiles ?<Link
                  to="/viewprofiles"
                  className="bg-yellow-400 hover:bg-yellow-500 h-10 flex items-center text-black font-bold py-3 px-5 rounded-lg transition  ease-in-out transform hover:scale-105"
                >
                  View All Candidates
                </Link>:"" } */}
              </div>
            </div>
          ) : (
            <p className="text-lg text-white">Loading...</p>
          )}
          <div className="flex items-center sm:hidden md:block ">
            <div className="w-[300px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterDash;
