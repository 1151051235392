//completed
import { useState, useEffect } from "react";
import {
  API_STATIC_URL,
  imgdefault,
} from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import { FaMoneyBillWave, FaRegEdit } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBriefcase } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Helmet } from "react-helmet";
import AdsComponent from "../GlobalComp/AdsComponent";
import { ADSENSESLOTS } from "../../apiConfig";
import { toast } from "react-toastify";
import {
  useDeleteJobMutation,
  useGetRecruiterQuery,
  useGetRecruiterTotalJobsMutation,
} from "../../redux/slice/recruiterServiceApiSlice";

const ListingDashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const [RecruiterTotal, { data: getRecruiterTotalJobs }] = useGetRecruiterTotalJobsMutation();
  const { data: getRecruiter } = useGetRecruiterQuery(currentUser?.refId);
  const [deleteJob] = useDeleteJobMutation();
  const pageSize = 10
  useEffect(() => {
    RecruiterTotal({
      id: currentUser?.refId,
      isActive: 1,
      pageNumber: currentPage,
      pageSize: pageSize,
    });
  }, []);

  useEffect(() => {
    if (getRecruiterTotalJobs?.dataCount) {
      setTotalPages(Math.ceil(getRecruiterTotalJobs.dataCount / pageSize));
    }
  }, [getRecruiterTotalJobs, pageSize]);


  const goToJobListing = (jobId) => {
    navigate(`/ListingDetails/${jobId}`);
  };

  const updateJob = (id) => {
    navigate(`/updatejob/${id}`);
  };

  const confirmDelete = (jobId) => {
    setSelectedJobId(jobId); // Set the selected job id
    setConfirmVisible(true); // Show the confirmation dialog
  };

  const deleteJobHandler = async () => {
    try {
      const response = await deleteJob(selectedJobId);

      if (response.data.success === true) {
        await handlePageChange(currentPage)
        // Refresh the listings
        toast.success("Job Deleted Successfully", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      } else {
        toast.error("Failed to Delete Job", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      console.error("Error deleting job:", error);
    } finally {
      setConfirmVisible(false); // Hide the confirmation dialog
    }
  };

  const backToDash = () => {
    navigate("/RecruiterDash");
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    RecruiterTotal({
      id: currentUser?.refId,
      isActive: 1,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  };

  const generatePaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(currentPage + 2, totalPages);

      if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - 4);
      } else if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      }

      if (startPage > 1) {
        buttons.push(1);
        if (startPage > 2) {
          buttons.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push("...");
        }
        buttons.push(totalPages);
      }
    }
    return buttons;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>V Link Jobs - Posted Jobs</title>
        <link rel="canonical" href="https://vlinkjobs.com/ListingDashboard" />
      </Helmet>

      <div className="App font-custom-font mt-20 relative bodybg overflow-auto">
        <div className="flex md:flex-row sm:flex-col w-full">
          <div className="m-10 flex  items-center sm:hidden md:block ">
            <div className="w-[350px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
          <div className="flex flex-col w-full items-center py-2">
            <h1 className="text-blue-900 text-3xl font-bold mb-2">
              Your Job Listings
            </h1>

            <div className="flex flex-col gap-2 w-full sm:px-2 items-center  text-xl font-semibold">
              {getRecruiterTotalJobs?.resullt?.length === 0 && (
                <div>You have not posted any Listings</div>
              )}
              {getRecruiterTotalJobs?.result?.length > 0 &&
                getRecruiterTotalJobs?.result?.map((item, index) => (
                  <div
                    key={item.jobId}
                    className="relative  grow rounded-lg shadow-lg bg-white sm:w-full border-2 border-gray-200 hover:border-gray-300 transition ease-in-out transform hover:scale-105"
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => goToJobListing(item.jobId)}
                    >
                      <div className="flex flex-col px-2">
                            <div className="font-bold text-xl text-gray-800 my-3 flex flex-row gap-2 justify-between items-center ">
                              <span className="ml-2 text-blue-800">{item.jobTitle}</span>
                              <div className="flex gap-4">
                                <button
                                  className=" sm:mb-2 hover:scale-110 transition"
                                  onClick={(e) => {
                                    e.stopPropagation();// Prevent the click event from bubbling up to the parent div
                                    updateJob(item.jobId);
                                  }}
                                >
                                  <FaRegEdit color="green" size="20" />
                                </button>
                                <button
                                  className=" sm:mb-2  hover:scale-110 transition"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
                                    confirmDelete(item.jobId);
                                  }}
                                >
                                  <MdDelete color="red" size="20" />
                                </button>
                              </div>
                            </div>
                            <div className="flex md:flex-row sm:flex-col md:items-center text-sm text-gray-600 ">
                              <div className="flex flex-row gap-2">
                                <img
                                  className="h-8 w-8 m-2"
                                  src={`${getRecruiter?.result?.imageUrl ? `${API_STATIC_URL}${getRecruiter?.result?.imageUrl}` : `${imgdefault}`}`}
                                  alt=""
                                />
                                <span className="flex flex-row mr-3 justify-center text-sm font-semibold items-center">
                                  {" "}
                                  {getRecruiter?.result?.companyName}
                                </span>
                              </div>
                              <span className="flex flex-row mx-3 md:justify-center items-center">
                                <FaLocationDot className="mx-2" />{" "}
                                {item.location}
                              </span>
                              <span className="flex flex-row mx-3 md:justify-center items-center">
                                <FaMoneyBillWave className="mx-2" />{" "}
                                {item.salary}
                              </span>
                              <span className="flex flex-row mx-3 md:justify-center items-center">
                                <FaBriefcase className="mx-2" />{" "}
                                {item.minExperience} to {item.maxExperience}{" "}
                                yrs
                              </span>
                            </div>
                        </div>
                    </div>
                  </div>
                ))}
              <div className="flex mt-5 gap-2">
                {generatePaginationButtons().map((button, index) => (
                  <button
                    key={index}
                    className={`rounded-lg bg-blue-400 px-2 font-semibold  border hover:scale-110 transition border-blue-400 hover:bg-blue-500 ${button === "..." ? "opacity-0" : ""
                      } ${currentPage === button
                        ? "bg-blue-600 text-white"
                        : "text-black"
                      }`}
                    onClick={() =>
                      typeof button === "number"
                        ? handlePageChange(button)
                        : null
                    }
                  >
                    {button}
                  </button>
                ))}
              </div>
              <button className="bg-gray-800 text-white font-normal rounded-md w-28 text-md p-2"
                  type="button" onClick={backToDash}>
                  Back
                </button>
            </div>
          </div>
          <div className="m-10 flex">
            <div className="w-[350px]">
              <AdsComponent dataAdSlot={ADSENSESLOTS.bannerAd} />
            </div>
          </div>
        </div>
      </div>

      {confirmVisible && (
        <div
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 font-custom-font"
        >
          <div className="flex flex-col bg-blue-300 rounded-lg">
            <div className="p-5 items-center flex ">
              <div className="text-2xl ">
                Are you sure you want to delete this job?
              </div>
            </div>
            <div className="flex gap-5 justify-end align-center text-right px-5 py-5 pt-0">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={deleteJobHandler}
              >
                Delete
              </button>
              <button
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setConfirmVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListingDashboard;
