import React from "react";
import { IMAGE_ROUTES } from "../../apiConfig";




const NavImage = () => {
    return(
        <div className='relative block group w-full h-[360px]'>
            <img className='absolute w-full h-[360px]' src={`${IMAGE_ROUTES.navImage}`} alt="" />
            <div className="relative flex pt-16 w-full justify-center">
                <h1 className='md:text-6xl sm:text-3xl'>Get Your Future Started</h1>
                <h6 className='md:text-3xl mt-14'>The easiest way to find a dream job</h6>
            </div>
        </div>
    );
}

export default NavImage;