import { Link } from "react-router-dom"
import { API_STATIC_URL, imgdefault } from "../../../apiConfig";
import { MdOutlinePendingActions } from "react-icons/md";
import { FcApproval } from "react-icons/fc";

const CardComponent = ({job,type}) => {
  return (
    <Link className="shadow-lg p-3 md:max-w-screen-md flex flex-col gap-4 border-2 text-black hover:bg-gray-200" to={`/admindash/jobdetailsadmin/${job.jobId}`}>
        <div className="flex justify-between gap-4 items-center">
        <div className="flex gap-4 items-center ">
            <img src={`${job.imageUrl ? `${API_STATIC_URL}${job.imageUrl}`: `${imgdefault}`}`} className="w-10 h-10" alt=""/>
        <p>{job.companyName}</p></div>
        </div>
        <p>Job Title: {job.jobTitle}</p>
        <div className="text-sm flex justify-between gap-4 font-semibold">
          <p>Recruiter Name: {job.recruiterName}</p>
          <p>Category: {job.categoryName}</p>
          <p>Subcategory: {job.subcategoryName}</p>
        </div>
        <div className="text-sm flex justify-between gap-4 font-semibold">
    <p>Email: {job.email}</p>
    <p>Mobile: {job.mobile}</p>
    {type==="Approve" ? <p className="text-red-500 flex gap-2"><MdOutlinePendingActions size="18" color="red" />Require Approval</p>
    :<p className="text-green-600 items-center flex gap-2"><FcApproval size="18" color="green" />Approved</p>}
        </div>
    </Link>
  )
}

export default CardComponent