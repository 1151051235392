import { useLocation, Navigate, Outlet, ScrollRestoration } from "react-router-dom"

const RequireAuthJoobSeeker = () => {
    const location = useLocation()
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    let content = (
        (currentUser?.token && currentUser?.userRoleId===3)?<Outlet/>:<Navigate to="/Login/3" state={{ from: location }} replace/>
    )
  return content
}



export const RequireAuthRecruiter = () => {
    const location = useLocation()
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));
    const content = (
     (currentUser?.token && currentUser.userRoleId ===2)?<Outlet/>:<Navigate to="/Login/2" state={{from:location}} replace />
    )
   
    return content
}


export const RequireAuthAdmin = () => {
    const location = useLocation()
    const currentUser = JSON.parse(localStorage.getItem(`vlinks`));

    const content = (
        ( currentUser?.token && currentUser.userRoleId===1 ) ? <Outlet />: <Navigate to="/AdminLogin" state={{ from: location }} replace />
    )
    return content
}


export function RootRouteComponent() {
  return (
    <>
      <ScrollRestoration />
      <Outlet/>
    </>
  );
}
export default RequireAuthJoobSeeker