import CreateJobForm from './CreateJobForm'

const CreateNewJob = () => {
  return (
    <>
    <CreateJobForm type="Create"/>
    </>
  )
}

export default CreateNewJob