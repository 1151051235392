import { toast } from "react-toastify";
import { useAccessProfilesMutation, useGetRecruiterQuery, useSaveRecruiterMutation } from "../../../redux/slice/recruiterServiceApiSlice";
import { useEffect, useState } from "react";
import { API_STATIC_URL, imgdefault } from "../../../apiConfig";
import { FaRegUser } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { LuDatabase } from "react-icons/lu";
import { GrHomeRounded } from "react-icons/gr";

export const EditRecruiter = ({ setEditCon, id }) => {
  const { data, isLoading } = useGetRecruiterQuery(id, { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [saveRecruiter] = useSaveRecruiterMutation();
  const [recruiterName, setRecruiterName] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [credits, setCredits] = useState(0);
  const [accessProfiles, setAccessProfiles] = useState(0);

  useEffect(() => {
    if (data?.result) {
      setRecruiterName(data?.result?.recruiterName);
      setAddress(data?.result?.address);
      setCompanyName(data?.result?.companyName);
      setMobile(data?.result?.mobile);
      setEmail(data?.result?.email);
      setCredits(data?.result?.creditsAvailable);
      setAccessProfiles(data?.result?.accessProfiles);
    }
  }, [data])

  const handleSubmit = async () => {
    try {
      const response = await saveRecruiter({
        id: data?.result?.id,
        recruiterName,
        companyName,
        address,
        mobile,
        email,
        accessProfiles,
        creditsAvailable: credits,
      });
      if (response?.data?.success) {
        toast.success("Profile updated successfully", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
        setEditCon(0);
      } else {
        toast.error("Failed to update profile", {
          autoClose: 1000,
          style: {
            width: "300px",
          },
        });
      }
    } catch (error) {
      toast.error("Error:", error, {
        autoClose: 1000,
        style: {
          width: "300px",
        },
      });
    }
  };


  let content;
  if (isLoading) content = <div className=" text-blue-500 font-bold text-xl ">Loading....</div>
  if (data?.result) {
    content = <div className="flex flex-col gap-3">
      <p>Edit Recruiter</p>
      <div className="sm:flex sm:flex-col md:grid md:grid-cols-2 text-sm gap-3 bg-blue-50 p-3 rounded-lg ">
        <div className="flex flex-col gap-2">
          <label className="text-gray-800 font-bold">Company Name</label>
          <input
            type="text"
            name="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Company Name"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-gray-800 font-bold">Name</label>
          <input
            type="text"
            name="recruiterName"
            value={recruiterName}
            onChange={(e) => setRecruiterName(e.target.value)}
            placeholder="Recruiter Name"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-gray-800 font-bold">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-gray-800 font-bold">Mobile</label>
          <input
            type="text"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Mobile"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>
        <div className="flex flex-col gap-2 col-span-2">
          <label className="text-gray-800 font-bold" htmlFor="credits">Available Credits</label>
          <input
            type="text"
            id="credits"
            value={credits}
            onChange={(e) => setCredits(e.target.value)}
            placeholder="credits"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>
        <div className="w-full flex flex-row items-center gap-1 px-3 mb-6 md:mb-0">
          <input type="checkbox" checked={!!accessProfiles}
            onChange={(e) => setAccessProfiles(e.target.checked ? 1 : 0)}
            inputProps={{ 'aria-label': 'controlled' }} />
          <label
            className="block uppercase tracking-wide text-blue-800 text-sm font-bold">
            Allow Access To Profiles 
          </label>
        </div>
        <div className="flex flex-col gap-2 col-span-2">
          <label className="text-gray-800 font-bold">Address</label>
          <textarea
            type="text"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            className="w-full mb-4 text-black px-4 py-2 border border-gray-400 rounded-lg"
          />
        </div>
        <div className="col-span-2">
          <button
            className="bg-green-800 text-white font-bold py-2 px-4 rounded-md mt-4"
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="bg-amber-600 text-white font-bold py-2 px-4 ml-4 rounded-md mt-4"
            onClick={() => setEditCon(0)}>
            Close
          </button>
        </div>
      </div>
    </div>
  }

  return <>
    {content}
  </>
}

export const ViewRecruiter = ({ setEditCon, id }) => {
  const { data, isLoading, refetch } = useGetRecruiterQuery(id, { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  let content;
  if (isLoading) content = <div className=" text-blue-500 font-bold text-xl ">Loading....</div>
  if (data?.result) {
    content = <div className="flex flex-col gap-3">
      <p>Recruiter Details</p>
      <div className="sm:flex sm:flex-col md:grid md:grid-cols-2 text-sm  text-black  gap-3  bg-blue-50 p-3 rounded-lg ">
        <div className="flex items-center gap-3 shadow-lg px-4 p-2 rounded-lg">
          <img src={`${data?.result?.imageUrl ? `${API_STATIC_URL}${data?.result?.imageUrl}` : `${imgdefault}`}`} alt="" className="h-12 w-12" />
          <p>{data?.result?.companyName}</p>
        </div>
        <div className="flex items-center gap-4 shadow-lg px-4 p-2 rounded-lg">
          <div className="bg-orange-300 p-2 rounded-md">
            <FaRegUser size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Recruiter Name</p>
            <p>{data?.result?.recruiterName}</p>
          </div>

        </div>
        <div className="flex items-center gap-4 shadow-lg px-4 p-2 rounded-lg">
          <div className="bg-orange-300 p-2 rounded-md">
            <FaPhone size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Mobile</p>
            <p>{data?.result?.mobile}</p>
          </div>
        </div>
        <div className="flex items-center gap-4 shadow-lg px-4 p-2 rounded-lg">
          <div className="bg-orange-300 p-2 rounded-md">
            <MdEmail size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Email</p>
            <p className="text-wrap">{data?.result?.email}</p>
          </div>

        </div>
        <div className="flex items-center gap-4 shadow-lg px-4 p-2 rounded-lg">
          <div className="bg-green-400 p-2 rounded-md">
            <LuDatabase size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Available Credits</p>
            <p>{data?.result?.creditsAvailable}</p>
          </div>
        </div>

        <div className="flex items-center gap-4 shadow-lg px-4 p-2 rounded-lg">
          <div className="bg-blue-400 p-2 rounded-md">
            <LuDatabase size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Used Credits</p>
            <p>{data?.result?.creditsUsed}</p>
          </div>
        </div>

        <div className="flex items-center gap-4 shadow-lg col-span-2 px-4 p-2 rounded-lg">
          <div className="bg-orange-300 p-2 rounded-md">
            <GrHomeRounded size="24" />
          </div>
          <div className="flex flex-col justify-between gap-3">
            <p>Address</p>
            <p>{data?.result?.address}</p>
          </div>
        </div>
        <div className="w-full flex flex-row items-center gap-1 px-3 mb-6 md:mb-0">
        <input type="checkbox" checked={!!data?.result?.accessProfiles}
            inputProps={{ 'aria-label': 'controlled' }} />
          <label
            className="block uppercase tracking-wide text-blue-800 text-sm font-bold">
            Allow Access To Profiles 
          </label>
        </div>

        <div>
          <button
            className="bg-amber-600 text-white font-bold py-2 px-4 ml-4 rounded-md mt-4"
            onClick={() => setEditCon(0)}>
            Close
          </button>
        </div>
      </div>
    </div>
  }

  return <>
    {content}
  </>
}