import { Link } from "react-router-dom";
import { FaFacebookSquare, FaPhoneAlt } from "react-icons/fa";
import { IoLogoLinkedin, IoMdMail } from "react-icons/io";
import { useGetJobCategoriesQuery } from "../../redux/slice/jobServiceApiSlice";
import { FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
  const { data: categories } = useGetJobCategoriesQuery("categoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="font-custom-font">
      <footer className="flex flex-col items-center bg-gray-800 text-center md:text-left text-black sm:pt-2">
        <div className="container md:p-4 ">
          <div className="grid md:grid-cols-3 grid-cols-1 mb-2">
            <div className="relative md:px-20">
              <h5 className="mb-3 font-semibold text-white">CATEGORIES</h5>
              <div className="flex flex-col">
                {categories?.result?.length > 0 &&
                  categories?.result?.map(category => (
                    <Link
                      key={category.id}
                      className="text-white cursor-pointer text-sm font-light"
                      to={`/JobSearch?catId=${category.id}`}
                    >
                      {category.categoryName}
                    </Link>
                  ))}
              </div>
            </div>
            <div className="relative mb-6 md:px-20">
              <h5 className="mb-3 font-semibold text-white">POLICIES</h5>
              <div className="mb-0 list-none">
                <div className="py-1">
                  <Link to="/TermsAndConditions" className="font-semibold text-white cursor-pointer">
                    Terms & Conditions
                  </Link>
                </div>
                <div className="py-1">
                  <Link to="/PrivacyPolicy" className="font-semibold text-white cursor-pointer">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
            <div className="mb-6 md:px-20">
              <Link to="/aboutus" className="flex mb-4 font-bold text-white hover:scale-105 transition">
                ABOUT US
              </Link>

              <h5 className="mb-2.5 mt-8 font-semibold text-white dark:text-neutral-200">
                CONTACT INFO
              </h5>

              <div className="mb-0 list-none">
                <div className="py-1">
                  <div className="text-neutral-800 dark:text-neutral-200 flex flex-row items-center">
                    <span className="flex flex-row items-center text-white text-sm gap-2 font-semibold">
                      <IoMdMail />
                      EMAIL:
                    </span>
                    <span className="flex text-white ml-2 transition ">
                      <a href="mailto:hr@vlinkjobs.com">
                        hr@vlinkjobs.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 mt-4 items-center">
                <h5 className=" font-semibold text-white dark:text-neutral-200 ">
                  SOCIAL MEDIA
                </h5>
                <Link to={"https://www.facebook.com"} target="_blank"><FaFacebookSquare  color="blue" size={24} /></Link>
                <Link to={"https://www.instagram.com/"} target="_blank"><FaSquareInstagram color="brown" size={24} /></Link>
                <Link to={"https://in.linkedin.com/"} target="_blank"><IoLogoLinkedin  color="blue" size={26} /></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center w-full p-4 text-center text-gray-400 bg-gray-900 dark:text-neutral-200">
        &copy;2024 Copyright:{" "}
          <div className="ml-2 mr-2"> V Link Jobs</div> All Rights Reserved
        </div>
      </footer>
    </div>
  );
};

export default Footer;
