import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { storertk } from './redux/storertk';
import { ToastContainer } from 'react-toastify';


const root = document.getElementById('root');
const rootContainer = createRoot(root);
rootContainer.render(
  <Provider store={storertk}>
    <ToastContainer />
    <App />
  </Provider>
);

