//completed
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE_ROUTES } from "../../apiConfig";
import { FaUser } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa6";
import { MdSpaceDashboard } from "react-icons/md";
import { IoLogInOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";

const RNavBar = () => {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("vlinks"));
  const [showButtons, setShowButtons] = useState(false);
  const LogOut = () => {
    localStorage.removeItem(`vlinks`);
    navigate("/")
    window.location.reload();
  }
  const goToRecuiterProfile = () => {
    if (currentUser?.userRoleId === 3) {
      navigate('/UserProfile');
    } else {
      navigate('/RecruiterProfile');
    }

  }
  const toggleButtons = () => {
    setShowButtons(prevState => !prevState);
  };

  const handleGoToAppliedJobs = () => {
    navigate('/CandidateApplications')
  }

  const handleBackToDashboard = () => {
    navigate('/RecruiterDash')
  }

  return (
    <div className='fixed top-0 w-full px-3 bg-white shadow-lg z-50 '>
      <div className="flex flex-wrap items-center justify-between mx-auto ">
        <NavLink to="/">
          <img src={`${IMAGE_ROUTES.mainLogo}`} className="relative h-16" alt="Logo" />
        </NavLink>
        <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={toggleButtons}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <img src="./assets/topbanner.png" alt="" className="rounded-lg h-11 hidden lg:block"></img>
        <div className={`w-full md:block md:w-auto ${showButtons ? 'block' : 'hidden'}`} id="navbar-default">
          {currentUser?.token ? <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 gap-2 sm:items-end rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 mr-4">
            {currentUser?.userRoleId === 2 && <button onClick={handleBackToDashboard} size="sm" className="text-blue-900 font-bold"> Dashboard</button>}
            {currentUser?.userRoleId === 3 && <button onClick={handleGoToAppliedJobs} size="sm" className="text-blue-900 font-bold"> My Jobs </button>}
            {currentUser?.userRoleId === 1 ? (null) : (<button onClick={goToRecuiterProfile} size="sm" className="text-blue-900 font-bold"> Profile</button>)}
            <button onClick={LogOut} size="sm" className="text-red-900 font-bold"> Logout </button>
          </ul> : <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 gap-2 sm:items-end rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
          <button type="button" onClick={()=>navigate("/Login/3")} size="sm"
            class="flex text-white bg-blue-900 hover:bg-blue-800 focus:outline-none font-medium rounded-full text-sm px-3 py-2 text-center me-2">
            Login<IoLogInOutline size={20} className="ml-1" /></button>

          <button type="button" onClick={()=>navigate("/Login/2")} size="sm"
            class="flex text-white bg-amber-800 hover:bg-amber-600 focus:outline-none font-medium rounded-full text-sm px-3 py-2 text-center me-2">
            Post New Job <FaBriefcase size={18} className="ml-2" /></button>
          </ul>}
        </div>
      </div>
    </div>
  );

}

export default RNavBar;