import React from "react";
import "./togglebutton.css";

const Toggle = ({ toggle, handleToggleChange }) => {
  return (
    <div className="flex gap-4 my-2 items-center">
       <button className={`p-2 px-3 border-2 border-gray-500 text-white rounded-lg hover:scale-105 ${!toggle?`bg-green-700`:`bg-blue-700`}`} onClick={handleToggleChange} disabled={!toggle}>Jobseeker</button>
        <div className='toggle-container' onClick={()=>{handleToggleChange()}}>
            <div className={`toggle-btn ${!toggle ? "disable" : ""}`}>
            </div>
        </div>
        <button className={`p-2 px-3 border-2 border-gray-500 text-white rounded-lg hover:scale-105 ${toggle?`bg-green-700`:`bg-blue-700`}`}  onClick={handleToggleChange} disabled={toggle}>Recruiter</button>
    </div>
   
  );
};

export default Toggle;